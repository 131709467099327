import React, { useEffect } from "react";
import {
  Route,
  Link,
  Routes,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import Header from "../Common/Header/Header";
import MeetingHeader from "../Common/Header/MeetingHeader";
import Sidebar from "../Common/Sidebar/Sidebar";
import ViewActionActivities from "./ViewActionActivities";
import ViewActionDetails from "./ViewActionDetails";
import { useSelector, useDispatch } from "react-redux";
import { logOut } from "../../redux/actions/authActions/authAction";
import { ToastContainer, toast } from "react-toastify";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import { fetchSingleAction } from "../../redux/actions/actionAction/ActionAction";
import Alert from "../Common/Alert";

const ViewActionPage = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  console.log(id);

  useEffect(() => {
    console.log("call--------------->>>>>>>>>>>>>>1");
    document.title = "View Action: MinutesVault";
    if (!authData.isInValidUser && accessToken) {
      // console.log("payload in ----------34-------", payload);
      dispatch(fetchSingleAction(id, accessToken));
    }
    return () => {
      console.log("call--------------->>>>>>>>>>>>>>");
    };
  }, [id]);

  const actionData = useSelector((state) => state.action);
  const employeeData = useSelector((state) => state.user);
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser) {
    console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnn");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const meetingData = useSelector((state) => state.meeting);

  const location = useLocation();
  const stateData = location.state;
  console.log(actionData);

  const accessToken = localStorage.getItem("accessToken");

  // if (actionData?.singleActionDetails && employeeData?.userData) {
  //   // if (
  //   //   (
  //   //     actionData?.singleActionDetails?.assignedUserId===employeeData?.userData?._id.toString() ||
  //   //     ((employeeData?.userData?.isMeetingOrganiser === true &&
  //   //       employeeData?.userData?.isAdmin === false) ||
  //   //       (employeeData?.userData?.isMeetingOrganiser === false &&
  //   //         employeeData?.userData?.isAdmin === true) ||
  //   //       (employeeData?.userData?.isMeetingOrganiser === true &&
  //   //         employeeData?.userData?.isAdmin === true) ||
  //   //       meetingData?.meetingDetails?.canWriteMOM === true)) ||
  //   //       (actionData?.singleActionDetails?.assignedUserId===employeeData?.userData?._id.toString() ||
  //   //     ((employeeData?.userData?.isMeetingOrganiser === true &&
  //   //       employeeData?.userData?.isAdmin === false) ||
  //   //       (employeeData?.userData?.isMeetingOrganiser === false &&
  //   //         employeeData?.userData?.isAdmin === true) ||
  //   //       (employeeData?.userData?.isMeetingOrganiser === true &&
  //   //         employeeData?.userData?.isAdmin === true)) ) ||
  //   //   (actionData?.singleActionDetails?.assignedUserId===employeeData?.userData?._id.toString() ||
  //   //     ((employeeData?.userData?.isMeetingOrganiser === true &&
  //   //       employeeData?.userData?.isAdmin === false) ||
  //   //       (employeeData?.userData?.isMeetingOrganiser === false &&
  //   //         employeeData?.userData?.isAdmin === true) ||
  //   //       (employeeData?.userData?.isMeetingOrganiser === true &&
  //   //         employeeData?.userData?.isAdmin === true) )
  //   //     )
  //   // ) {
  //   //   console.log("is allowed");
  //   //   // alert("inside if")
  //   // } else {
  //   //   // alert("inside else")
  //   //   console.log("blocked");
  //   //   console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnn");
  //   //   if(accessToken){
  //   //   localStorage.removeItem("accessToken");
  //   //   localStorage.removeItem("userData");
  //   //   localStorage.removeItem("rememberMe");
  //   //   // dispatch(logOut());
  //   //   // navigate("/login");
  //   //   toast.error("Sorry. You do not have permission for this page.", {
  //   //     position: "top-right",
  //   //     autoClose: 3000,
  //   //     hideProgressBar: false,
  //   //     closeOnClick: true,
  //   //     pauseOnHover: true,
  //   //     draggable: true,
  //   //     progress: undefined,
  //   //     theme: "colored",
  //   //     // transition: Bounce,
  //   //   });
  //   // }
  //   // }
  //   let isRoleAllowed = false;

  //   if (employeeData?.userData?.isAdmin === true) {
  //     console.log("allowed1");
  //     isRoleAllowed = true;

  //     // alert("inside if")
  //   } else {
  //     // alert("inside else")
  //     console.log("blocked1");
  //     if (employeeData?.userData?.isMeetingOrganiser === true) {
  //       console.log("allowed2");
  //       isRoleAllowed = true;
  //     } else {
  //       console.log("blocked2");

  //       if (
  //         actionData?.singleActionDetails?.createdById?.toString() ===
  //         employeeData?.userData?._id.toString()
  //       ) {
  //         console.log("allowed4");
  //         isRoleAllowed = true;
  //       } else {
  //         console.log("blocked3");
  //         isRoleAllowed = false;
  //       }
  //     }

  //     if (isRoleAllowed === true) {
  //       console.log("final allowed");
  //     } else {
  //       console.log("final blocked");
  //       if (accessToken) {
  //         localStorage.removeItem("accessToken");
  //         localStorage.removeItem("userData");
  //         localStorage.removeItem("rememberMe");
  //         dispatch(logOut());
  //         navigate("/login");
  //         toast.error("Sorry. You do not have permission for this page.", {
  //           position: "top-right",
  //           autoClose: 3000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //           // transition: Bounce,
  //         });
  //       }
  //     }
  //   }
  // }

  return (
    <>
      <Header />
      <Sidebar />
      {actionData?.singleActionDetails !== null ? (
        <div className="main-content">
          <div className="meeting-header-text">
            <h4>Action Details</h4>
          </div>
          <div className="row">
            <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              {/* <div className="meeting-header-text">
              <h4>Meeting Preview</h4>
            </div> */}
              <ViewActionDetails actionId={id} />
            </div>
            <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              {/* <div className="meeting-header-text">
              <h4>Action Activities</h4>
            </div> */}
              <ViewActionActivities actionId={id} />
            </div>
          </div>
        </div>
      ) : // ) : actionData?.singleActionDetails === null ? (
      //   <div className="main-content">
      //     <div className="row">
      //       <NoDataFound />
      //     </div>
      //   </div>
      // ) : (
      //   <div>
      //     <Loader />
      //   </div>
      // )}
      actionData?.singleActionDetails === null &&
        !actionData.loading &&
        !actionData.isSuccess ? (
        <div className="main-content">
          <div className="meeting-header-text">
            <h4>Action Details</h4>
          </div>
          <div className="row">
            {/* <h4>Meeting Details</h4> */}
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <form className="mt-2 details-form details-form-right">
                <div className="row">
                <Alert
                status={"info"}
                message={"No data available."}
                timeoutSeconds={0}
              />
                <NoDataFound dataType={"meeting"} />
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="main-content">
          <div className="meeting-header-text">
            <h4>Action Details</h4>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              {/* <div className="meeting-header-text">
         <h4>Meeting Details</h4>
       </div> */}

              <form className="mt-2 details-form details-form-right">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Loader />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewActionPage;
