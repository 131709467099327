import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as constantMessages from "../../constants/constatntMessages";
import { setFinalMinuteData } from "../../redux/actions/minuteActions/MinuteAction";
import { getEmployeeList } from "../../redux/actions/userAction/userAction";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { checkCharacterLeft } from "../../helpers/commonHelpers";
import { textBoxStrLength } from "../../config/config";
import { fetchAttendeesList } from "../../redux/actions/meetingActions/MeetingAction";
import Select from "react-select";
import { rejectReassignRequest } from "../../redux/actions/actionAction/ActionAction";
import Spinner from "react-bootstrap/Spinner";

const ReassignModal = (props) => {
  console.log(props);
  const regularExpression = /^[0-9a-zA-Z .(),/-]+$/;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const meetingData = useSelector((state) => state.meeting);
  const loginUserData = useSelector((state) => state.user);
  const actionData = useSelector((state) => state.action);
  const employeeData = useSelector((state) => state.user);
  const [reason, setReason] = useState("");
  const [selectedAttendeeOption, setSelectedAttendeeOption] = useState([
    { label: "Name / Email Address", value: "" },
  ]);
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState([
    { label: "Name / Emp Id", value: "" },
  ]);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    reason: "",
    priority: "",
    attendyType: "fromPreviousMeeting",
    responsiblePersonId: null,
    name: "",
    email: "",
    acceptStatus: "accept",
    attendeeId: null,
  });
  console.log(formData);
  useEffect(() => {
    if (formData.attendyType === "fromPreviousMeeting" && accessToken) {
      dispatch(fetchAttendeesList(userData.organizationId, accessToken));
    }
    if (actionData?.isSuccess === true) {
      setReason("");
      setErrors({});
      props.setIsReassignModalOpen(false);
      setFormData({
        ...formData,
        reason: "",
        priority: "",
        attendyType: "fromPreviousMeeting",
        responsiblePersonId: "",
        name: "",
        email: "",
        acceptStatus: "accept",
      });
    }
    return () => {
      console.log("call--------------->>>>>>>>>>>>>>");
      // setFormData({
      //   ...formData,
      //   reason: "",
      //   priority: "",
      //   attendyType: "fromPreviousMeeting",
      //   responsiblePersonId: "",
      //   name: "",
      //   email: "",
      //   acceptStatus: "accept",
      // });
    };
  }, [actionData?.isSuccess]);
  console.log(actionData?.singleActionDetails);
  const handleSubmit = () => {
    console.log(formData.acceptStatus);
    const newErrors = validateForm();
    console.log(newErrors);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      console.log(formData);

      if (formData.attendeeId) {
        console.log("aaaaaa");
        const resPersonNameFromAttendees = meetingData.attendeesList.find(
          (item) => item._id.toString() === formData.attendeeId
        )?.name;
        const resPersonNameFromEmployee = employeeData.employeeList.find(
          (item) => item._id.toString() === formData.attendeeId
        )?.name;
        console.log(resPersonNameFromAttendees);
        console.log(resPersonNameFromEmployee, resPersonNameFromAttendees);
        if (formData.acceptStatus === "accept") {
          props.handleSubmit({
            ...formData,
            reassignedUserName: resPersonNameFromEmployee
              ? resPersonNameFromEmployee
              : resPersonNameFromAttendees,
          });
          // setFormData({
          //   ...formData,
          //   reason: "",
          //   priority: "",
          //   attendyType: "fromPreviousMeeting",
          //   responsiblePersonId: "",
          //   name: "",
          //   email: "",
          // });
        } else {
          console.log("innnnnnnnnnnnnnnnnnn");
          const payload = {
            rejectDetails: formData.reason,
            assignedUserId: formData.attendeeId,
          };
          dispatch(rejectReassignRequest(props.actionId, payload, accessToken));
        }
      } else {
        console.log(actionData?.singleActionDetails);
        console.log(formData);
        if (formData.acceptStatus === "reject") {
          const payload = {
            rejectDetails: formData.reason,
            assignedUserId: actionData?.singleActionDetails?.assignedUserId,
          };
          dispatch(rejectReassignRequest(props.actionId, payload, accessToken));
        } else {
          console.log(formData);
          props.handleSubmit({
            ...formData,
          });
        }
      }
    } else {
      console.log("else part");
    }
  };
  console.log(actionData?.singleActionDetails?.assignedUserId);
  const validateForm = () => {
    console.error(reason);
    const errors = {};
    if (formData.acceptStatus === "accept") {
      if (!formData.reason.trim()) {
        errors.reason = constantMessages.reasonRequired;
      }
      else if (
        formData.reason.trim().length < 3 ||
        formData.reason.trim().length > 100
      ) {
        errors.reason = constantMessages.stringLengthError;
      }

      if (formData.attendyType === "addNewPeople") {
        //  const regularExpression = /^[A-Za-z\s]+$/; //returns true if matched, vaidates for a-z and A-Z and white space
        if (!formData.name.trim()) {
          errors.name = constantMessages.nameRequired;
        } else if (!regularExpression.test(formData.name)) {
          errors.name = constantMessages.invalidName;
        } else if (
          formData.name.trim().length < 3 ||
          formData.name.trim().length > 100
        ) {
          errors.title = constantMessages.stringLengthError;
        }

        if (!formData.email.trim()) {
          errors.email = constantMessages.emailRequired;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          errors.email = constantMessages.invalidEmail;
        }
      }
      else{
        if (!formData.priority.trim()) {
          errors.priority = constantMessages.priorityRequired;
        }
        if (formData.attendeeId===null) {
          errors.responsiblePerson = constantMessages.responsiblePersonRequired;
        }
      }

    
    } else {
      if (!formData.reason.trim()) {
        errors.reason = constantMessages.reasonRequired;
      } else if (
        formData.reason.trim().length < 3 ||
        formData.reason.trim().length > 100
      ) {
        errors.reason = constantMessages.smallStringLengthError;
      }
    }
    setErrors(errors);
    return errors;
  };


  const validateReasonForm = () => {
    console.error(reason);
    const errors = {};
    if (formData.acceptStatus === "accept") {
      if (!formData.reason.trim()) {
        errors.reason = constantMessages.reasonRequired;
      }
      else if (
        formData.reason.trim().length < 3 ||
        formData.reason.trim().length > 100
      ) {
        errors.reason = constantMessages.smallStringLengthError;
      }

      if (formData.attendyType === "addNewPeople") {
        //  const regularExpression = /^[A-Za-z\s]+$/; //returns true if matched, vaidates for a-z and A-Z and white space
        if (!formData.name.trim()) {
          errors.name = constantMessages.nameRequired;
        } else if (!regularExpression.test(formData.name)) {
          errors.name = constantMessages.invalidName;
        } else if (
          formData.name.trim().length < 3 ||
          formData.name.trim().length > 100
        ) {
          errors.title = constantMessages.stringLengthError;
        }

        if (!formData.email.trim()) {
          errors.email = constantMessages.emailRequired;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          errors.email = constantMessages.invalidEmail;
        }
      }

     
    } else {
      if (!formData.reason.trim()) {
        errors.reason = constantMessages.reasonRequired;
      } else if (
        formData.reason.trim().length < 3 ||
        formData.reason.trim().length > 100
      ) {
        errors.reason = constantMessages.stringLengthError;
      }
    }
    setErrors(errors);
    return errors;
  };

  const closeModal = () => {
    setReason("");
    setErrors({});
    props.setIsReassignModalOpen(false);
  };

  const handleChange = (e) => {
    console.log(e);
    setErrors({});
    const { name, value } = e.target;
    console.log(name, value);
    if (name === "reason") {
      if (value.length <= textBoxStrLength) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (value === "fromEmployeeList") {
      const payload = {
        organizationId: userData.organizationId,
      };

      dispatch(getEmployeeList(payload, accessToken));
    }
  };
  const nameFieldValidationCheck = (e) => {
    console.log("on blur");
    const errors = {};
    // if (!formData.password) {
    //   errors.roomId = constantMessages.roomRequired;
    //   setErrors(errors);
    // }
    // const regularExpression = /^[A-Za-z\s]+$/; //returns true if matched, vaidates for a-z and A-Z and white space
    if (!formData.name.trim()) {
      errors.name = constantMessages.nameRequired;
    } else if (!regularExpression.test(formData.name)) {
      errors.name = constantMessages.invalidName;
    } else if (
      formData.name.trim().length < 3 ||
      formData.name.trim().length > 30
    ) {
      errors.name = constantMessages.nameLengthError;
    }
    setErrors(errors);
  };

  const emailFieldValidationCheck = (e) => {
    const errors = {};
    // if (!formData.password) {
    //   errors.roomId = constantMessages.roomRequired;
    //   setErrors(errors);
    // }
    // if (!formData.password) {
    if (!formData.email.trim()) {
      errors.email = constantMessages.emailRequired;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = constantMessages.invalidEmail;
    }

    setErrors(errors);
    // }
  };

  if (meetingData?.attendeesList && meetingData?.attendeesList?.length !== 0) {
    var attendeeOptions = meetingData?.attendeesList?.map((attendee) => {
      console.log(attendee);
      const returnData = {
        value: attendee._id,
        label: attendee.name + " / " + attendee.email,
        name: "attendeeId",
      };
      console.log(returnData);
      return returnData;
    });
  }
  if (employeeData?.employeeList?.length !== 0) {
    var employeeOptions = employeeData?.employeeList?.map((attendee) => {
      console.log(attendee);
      const returnData = {
        value: attendee._id,
        label: attendee.name + " / " + attendee.empId,
        name: "attendeeId",
      };
      console.log(returnData);
      return returnData;
    });
  }

  const handleAttendeeChange = (e) => {
    // dispatch(updateIsCreateMeetingProcessed(false));
    setErrors({});
    console.log("on change------------------->>>>>>", e);
    const { name, value } = e;
    setFormData({
      ...formData,
      [name]: value,
    });
    setSelectedAttendeeOption(e);
  };
  const handleEmployeeChange = (e) => {
    // dispatch(updateIsCreateMeetingProcessed(false));
    setErrors({});
    console.log("on change------------------->>>>>>", e);
    const { name, value } = e;
    setFormData({
      ...formData,
      [name]: value,
    });
    setSelectedEmployeeOption(e);
  };

  return (
    <>
      <Modal show={props.isModalOpen} onHide={closeModal}>
        {props.activity ? (
          <>
            <div className="modal-header">
              <h4 className="modal-title">Reassignment Request</h4>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={closeModal}
              ></button>
            </div>
          </>
        ) : (
          <div className="modal-header">
            <h4 className="modal-title">Reassign </h4>
            
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={closeModal}
            ></button>
          </div>
        )}
        <div className="modal-body">
          {props.activity ? (
            <>
              <div className="delete-txt">
                <p>{props.activity}</p>
                {/* <textarea
         cols="40"
         rows="4"
         id="remark"
         name="remark"
         readOnly={true}
         value={activityDetails}
         required
       ></textarea> */}
              </div>
              <div className="minutes-border"></div>
            </>
          ) : null}
          <form>
            {props.activity ? (
              <div className="d-flex w-100">
                <div className="form-check form-check-inline">
                  <input
                    id="flexRadioDefault1"
                    className="form-check-input"
                    type="radio"
                    name="acceptStatus"
                    value="accept"
                    onChange={handleChange}
                    checked={formData.acceptStatus === "accept"}
                  />
                  <label
                    for="flexRadioDefault1"
                    id="flexRadioDefault1"
                    className="form-check-label"
                  >
                    Accept
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      id="flexRadioDefault2"
                      type="radio"
                      name="acceptStatus"
                      value="reject"
                      onChange={handleChange}
                      checked={formData.acceptStatus === "reject"}
                    />
                    <label
                      className="form-check-label"
                      for="flexRadioDefault2"
                      id="flexRadioDefault2"
                    >
                      Reject
                    </label>
                  </div>
                </div>
              </div>
            ) : null}
            {formData.acceptStatus === "accept" ? (
              <>
                <div className={`form-group ${props.activity ? "mt-3" : ""}`}>
                  <div className="position-relative">
                    <label className="pb-2 input-date">Reason
                    <span className="star-mark">*</span>
                    </label>
                    <div>
                      <textarea
                        cols="40"
                        rows="4"
                        id="remark"
                        name="reason"
                        onBlur={validateReasonForm}
                        onChange={handleChange}
                        value={formData.reason}
                        placeholder="Enter your reason here..."
                        required
                      ></textarea>
                      <p className="success-message">
                        {checkCharacterLeft(formData.reason)} Characters left
                      </p>
                      {errors.reason && (
                        <span className="error-message">{errors.reason}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="position-relative">
                    <label className="pb-2 input-date">Priority</label>
                    <div>
                      <select
                        name="priority"
                        id="priority"
                        onChange={handleChange}
                      >
                        <option value="">Select Priority</option>
                        <option value="HIGH">High</option>
                        <option value="NORMAL">Normal</option>
                        <option value="LOW">Low</option>
                      </select>
                    </div>
                  </div>
                  {errors.priority ? (
                    <span className="error-message">{errors.priority}</span>
                  ) : null}
                </div>

                {/* <div className="form-group">
            <div className="position-relative">
              <label className="pb-2 input-date">Priority</label>
              <div>
                <select name="priority" id="priority" onChange={handleChange}>
                  <option value="">Select Priority</option>
                  <option value="HIGH">High</option>
                  <option value="NORMAL">Normal</option>
                  <option value="LOW">Low</option>
                </select>
              </div>
            </div>
            {errors.priority ? (
              <span className="error-message">{errors.priority}</span>
            ) : null}
          </div> */}

                <div className="form-group">
                  <label className="pb-2">Select Responsible Person</label>

                  <div className="w-100">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="attendyType"
                        value="fromPreviousMeeting"
                        id="flexRadioDefault1"
                        onChange={handleChange}
                        checked={formData.attendyType === "fromPreviousMeeting"}
                      />
                      <label
                        className="mb-2 form-check-label"
                        for="flexRadioDefault1"
                        id="flexRadioDefault1"
                      >
                        Select From Previous Meetings
                      </label>
                    </div>

                    <div className="form-check-inline">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="attendyType"
                          value="fromEmployeeList"
                          id="flexRadioDefault2"
                          onChange={handleChange}
                          checked={formData.attendyType === "fromEmployeeList"}
                        />
                        <label
                          className=" mb-2 form-check-label"
                          for="flexRadioDefault2"
                          id="flexRadioDefault2"
                        >
                          Select From Employees
                        </label>
                      </div>
                     
                    </div>

                    <div className="form-check-inline">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="attendyType"
                          value="addNewPeople"
                          id="flexRadioDefault3"
                          checked={formData.attendyType === "addNewPeople"}
                          // checked={this.state.value === 1}
                          onChange={handleChange}
                        />
                        <label
                          className=" mb-2 form-check-label"
                          for="flexRadioDefault3"
                          id="flexRadioDefault3"
                        >
                          Add New People
                        </label>
                      </div>
                    </div>
                  </div>

                  {formData.attendyType === "fromPreviousMeeting" ? (
                    // <select
                    //   className=""
                    //   // name="attendeesData"
                    //   // onChange={handleAttendeeChange}
                    //   // value={attendeeId}

                    //   onChange={handleChange}
                    //   name="responsiblePersonId"
                    //   value={formData.responsiblePersonId}
                    // >
                    //   <option value="" selected={true}>
                    //     {" "}
                    //     Name / Email Address
                    //   </option>
                    //   {meetingData.attendeesList &&
                    //     meetingData.attendeesList.map((attendee, index) => {
                    //       return (
                    //         <option key={index} value={attendee._id}>
                    //           {attendee.name} / {attendee.email}
                    //         </option>
                    //       );
                    //     })}
                    // </select>

                    <Select
                      className="fltr-opt"
                      placeholder="Name / Email Address"
                      // defaultValue={attendeeOptions}
                      name="attendeeId"
                      // onChange={setSelectedOption}
                      options={attendeeOptions}
                      onChange={handleAttendeeChange}
                      value={selectedAttendeeOption}
                    />
                  ) : formData.attendyType === "fromEmployeeList" ? (
                    // <select
                    //   className="mb-2"
                    //   onChange={handleChange}
                    //   name="responsiblePersonId"
                    //   value={formData.responsiblePersonId}
                    //   selected={true}
                    // >
                    //   <option value="" selected>
                    //     {" "}
                    //     Name / Employee ID
                    //   </option>
                    //   {employeeData.employeeList &&
                    //     employeeData.employeeList.map((employee, index) => {
                    //       return (
                    //         <option value={employee._id} key={index}>
                    //           {employee.name} / {employee.empId}
                    //         </option>
                    //       );
                    //     })}
                    // </select>
                    <Select
                      className="fltr-opt"
                      placeholder="Name / Employee ID"
                      // defaultValue={employeeOptions}
                      name="attendeeId"
                      // onChange={setSelectedOption}
                      options={employeeOptions}
                      value={selectedEmployeeOption}
                      onChange={handleEmployeeChange}
                    />
                  ) : formData.attendyType === "addNewPeople" ? (
                    <div className="form-group">
                      <label className="pb-2">Add New People</label>
                      <div className="row">
                        <div className="col-xl-6">
                          <input
                            type="text"
                            autoComplete="off"
                            placeholder="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            onBlur={nameFieldValidationCheck}
                          />
                          {errors.name && (
                            <span className="error-message">{errors.name}</span>
                          )}
                        </div>
                        <div className="col-xl-6">
                          <input
                            type="text"
                            placeholder="Email"
                            autoComplete="off"
                            name="email"
                            value={formData.email}
                            onBlur={emailFieldValidationCheck}
                            onChange={handleChange}
                          />
                          {errors.email && (
                            <span className="error-message">
                              {errors.email}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* {errors.responsiblePersonId && (
                <span className="error-message">
                  {errors.responsiblePersonId}
                </span>
              )} */}
               {errors.responsiblePerson ? (
                    <span className="error-message">{errors.responsiblePerson}</span>
                  ) : null}
                </div>
              </>
            ) : (
              <div className="form-group">
                <div className="position-relative">
                  <label className="pb-2 input-date">Reason</label>
                  <div>
                    <textarea
                      cols="40"
                      rows="4"
                      id="remark"
                      name="reason"
                      onBlur={validateForm}
                      onChange={handleChange}
                      value={formData.reason}
                      placeholder="Enter your reason here..."
                      required
                    ></textarea>
                    <p className="success-message">
                      {checkCharacterLeft(formData.reason)} Characters left
                    </p>
                    {errors.reason && (
                      <span className="error-message">{errors.reason}</span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
        <div className="modal-footer">
          {/* <button
                type="button"
                onClick={closeModal}
                className="reset btn btn-light bg-white border-primary text-primary "
              >
                <p>Close</p>
              </button> */}
          { !actionData.loading &&
            actionData.isSuccess &&
          formData.acceptStatus === "accept" ? (
            <button
              type="button"
              onClick={handleSubmit}
              className="Mom-btn btn btn-secondary bg-primary border-primary"
            >
              <p>Reassign</p>

            </button>
          ) : !actionData.loading &&
            actionData.isSuccess &&
            formData.acceptStatus === "reject" ? (
            <button
              type="button"
              onClick={handleSubmit}
              className="Mom-btn btn btn-secondary bg-primary border-primary"
            >
              <p>Reject</p>
            </button>
          ) : (
            <button className="Mom-btn btn btn-secondary bg-primary border-primary" type="button">
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Processing...
            </button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ReassignModal;
