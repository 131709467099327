import {
  CREATE_MEETING_RESPONSE,
  FAIL_REQUEST,
  GET_ATTENDEES_LIST,
  GET_CREATE_MEETING_STEPS,
  GET_MEETING_LIST,
  MAKE_REQUEST,
  MAKE_RSVP_UPDATE_REQUEST,
  UPDATE_ISCREATE_MEETING_PROCESSED,
  UPDATE_RSVP,
  SET_ATTENDEES,
  UPDATE_MEETING_RESPONSE,
  LOAD_PREVIOUS_STEP,
  SET_SINGLE_MEETING_DETAILS,
  SET_MEETING_VIEW_PAGE,
  SET_CREATE_NEW_MEETING_PAGE,
  UNSET_SINGLE_MEETING_DETAILS,
  UPDATE_STEP,
  UPDATE_FETCH_MEETING_LIST_STATUS,
  SET_AGENDA_AND_MINUTES_DETAILS,
  SET_MEETING_ACTIVITIES_DETAILS,
  SET_USER_MEETING_LIST,
  UPDATE_ATTENDANCE_RESPONSE,
  UPDATE_MEETING_ISSUCCESS,
  SET_DOWNLOAD_MOM_RESPONSE,
  SET_TIMELINE_RESPONSE,
  SET_PARENT_AGENDA_AND_MINUTES_DETAILS,
  SET_GENERATE_MOM_RESPONSE,
  SET_MEETING_STATISTICS_RESPONSE,
  MAKE_STATISTIC_REQUEST,
  UPDATE_ISSHOW_UPDATE_NOTIFY
} from "../actions/meetingActions/actionTypes";
import { CREATE_MINUTE_RESPONSE } from "../actions/minuteActions/actionTypes";

const initialObject = {
  loading: false,
  statisticloading:false,
  ownerList: [],
  meetingList: [],
  userMeetingList: [],
  message: "",
  totalCount: 0,
  isSuccess: false,
  statusData: ["closed", "scheduled", "rescheduled", "cancelled", "draft"],
  attendeesList: [],
  isRsvpUpdated: false,
  singleMeetingDetails: null,
  step: 0,
  isCreateMeetingProcessed: false,
  apiProcessed: false,
  checkStep: null,
  meetingId: null,
  isViewMeetingPage: false,
  isNewMeetingPage: false,
  isUpdateStep: false,
  isFetchedMeetingList: false,
  agendaDetails: [],
  meetingDetails: null,
  meetingActivitiesDetails: [],
  isAttendanceStatus: false,
  timeLineDatas: [],
  parentAgendaDetails: [],
  parentMeetingDetails: null,
  meetingStatisticsData: null,
  isShowUpdateNotify:false
};

export const meetingReducer = (state = initialObject, action) => {
  console.log("----------------------->>>>>>", action);
  switch (action.type) {
    case MAKE_REQUEST:
      return {
        ...state,
        loading: true,
        // meetingList: []
      };

      case MAKE_STATISTIC_REQUEST:
        return {
          ...state,
          statisticloading: true,
          // meetingList: []
        };
      
    case FAIL_REQUEST:
      return {
        ...state,
        loading: false,
        statisticloading:false,
        message: action.payload.message,
        isCreateMeetingProcessed: true,
        isSuccess: action.payload.success,
      };

    case GET_MEETING_LIST:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        meetingList: action.payload.data?.meetingData,
        totalCount: action.payload.data?.totalCount,
        isSuccess: action.payload.success,
        isFetchedMeetingList: false,
      };
    case GET_ATTENDEES_LIST:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        attendeesList: action.payload.data,
        isSuccess: action.payload.success,
      };

    case MAKE_RSVP_UPDATE_REQUEST:
      return {
        ...state,
        isRsvpUpdated: false,
        // meetingList: []
      };

    case UPDATE_RSVP:
      return {
        ...state,
        //  loading: false,
        message: action.payload.message,
        isRsvpUpdated: action.payload.success,
      };

    case CREATE_MEETING_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
        isCreateMeetingProcessed: true,
        step: action.payload.success ? 1 : 0,
        isNewMeetingPage: false,
      };

    case GET_CREATE_MEETING_STEPS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
        singleMeetingDetails: action.payload.data,
        step: action.payload.data ? action.payload.data.step : 0,
        meetingId: action.payload.data._id,
      };

    case UPDATE_ISCREATE_MEETING_PROCESSED:
      return {
        ...state,
        //  loading: false,
        isCreateMeetingProcessed: false,
      };

    case SET_ATTENDEES:
      return {
        ...state,
        //  loading: false,
        attendeesList: action.payload.data,
        message: action.payload.message,
        isSuccess: action.payload.success,
      };

    case UPDATE_MEETING_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
        step:
          action.payload.success && !state.isUpdateStep
            ? state.step + 1
            : state.step,
        isCreateMeetingProcessed: true,
        checkStep: false,
        isNewMeetingPage: false,
        isUpdateStep: state.step === 3 ? false : true,
        isShowUpdateNotify:true
      };
    case LOAD_PREVIOUS_STEP:
      return {
        ...state,
        step: action.payload,
        checkStep: false,
      };
    case SET_SINGLE_MEETING_DETAILS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
        singleMeetingDetails: action.payload.data,
        meetingId: action.payload.meetingId,
        step: state.isUpdateStep ? state.step : action.payload.data.step,
      };
    case SET_MEETING_VIEW_PAGE:
      return {
        ...state,
        meetingId: action.payload.meetingId,
        isViewMeetingPage: true,
      };
    case SET_CREATE_NEW_MEETING_PAGE:
      return {
        ...state,
        // meetingId:action.payload.meetingId,
        isNewMeetingPage: action.payload,
        singleMeetingDetails: null,
        isCreateMeetingProcessed: false,
        isUpdateStep: false,
      };
    case UNSET_SINGLE_MEETING_DETAILS:
      return {
        ...state,
        loading: false,
        // message: action.payload.message,
        // isSuccess: action.payload.success,
        singleMeetingDetails: null,
        meetingId: null,
      };
    case UPDATE_STEP:
      return {
        ...state,
        step: action.payload.step,
        isUpdateStep: action.payload.isUpdateStep,
      };

    case UPDATE_FETCH_MEETING_LIST_STATUS:
      return {
        ...state,
        isFetchedMeetingList: action.payload,
      };
    case SET_AGENDA_AND_MINUTES_DETAILS:
      return {
        ...state,
        loading: false,
        agendaDetails: action.payload.agendaDetails,
        meetingDetails: action.payload.meetingDetail,
        message: action.payload.message,
        isSuccess: action.payload.success,
        isAttendanceStatus: false,
      };

    case SET_PARENT_AGENDA_AND_MINUTES_DETAILS:
      return {
        ...state,
        loading: false,
        parentAgendaDetails: action.payload?.agendaDetails
          ? action.payload?.agendaDetails
          : [],
        // parentMeetingDetails: action.payload.meetingDetail,
        message: action.payload?.message,
        isSuccess: action.payload?.success,
        isAttendanceStatus: false,
      };

    case SET_MEETING_ACTIVITIES_DETAILS:
      return {
        ...state,
        loading: false,
        meetingActivitiesDetails: action.payload.data,
        message: action.payload.message,
        isSuccess: action.payload.success,
      };
    case SET_USER_MEETING_LIST:
      return {
        ...state,
        loading: false,
        userMeetingList: action.payload?.data?.meetingDetail,
        ownerList: action.payload?.data?.ownerDetails,
        message: action.payload.message,
        isSuccess: action.payload.success,
      };
    case UPDATE_ATTENDANCE_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
        isAttendanceStatus: action.payload.success ? true : false,
      };

    case UPDATE_MEETING_ISSUCCESS:
      return {
        ...state,
        isSuccess: action.payload,
      };

    case SET_DOWNLOAD_MOM_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
      };

    case SET_TIMELINE_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
        timeLineDatas: action.payload?.data,
      };

    case SET_GENERATE_MOM_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
        // timeLineDatas:action.payload?.data
      };
      case UPDATE_ISSHOW_UPDATE_NOTIFY:
        return {
          ...state,
          isShowUpdateNotify: action.payload
        };

    case SET_MEETING_STATISTICS_RESPONSE:
      return {
        ...state,
        statisticloading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
        meetingStatisticsData: action.payload?.data,
      };
    default:
      return state;
  }
};
