import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Navigate, Link, useParams } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import {
  downloadMOM,
  getAgendaWithMinutesDetails,
  getSingleMeetingDetails,
  unSetSingleMeetingDetails,
} from "../../redux/actions/meetingActions/MeetingAction";
import Header from "../Common/Header/Header";
import MeetingHeader from "../Common/Header/MeetingHeader";
import Sidebar from "../Common/Sidebar/Sidebar";
import {
  convertFirstLetterOfFullNameToCapital,
  customName,
  formatDateTimeFormat,
  getTimeSession,
} from "../../helpers/commonHelpers";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";
import {
  addAgendaDetails,
  createMinutes,
  processAmendmentRequest,
  rejectAcceptMinute,
  setSuccess,
  updateAmendmentRequest,
} from "../../redux/actions/minuteActions/MinuteAction";
import MinutesRsvpStatusModal from "./MinutesRsvpStatusModal";
import AttendeesModal from "../Meeting/AttendeesModal";
import LoaderButton from "../Common/LoaderButton";
import AmendmentRequestModal from "./AmendmentRequestModal";
import AmendmentRequestViewModal from "./AmendmentRequestViewModal";
import MeetingActivities from "../Meeting/MeetingActivities";
import EditAgendaModal from "./EditAgendaModal";
import DeleteAgendaModal from "./DeleteAgendaModal";
import EditMinuteModal from "./EditMinuteModal";
import DeleteMeetingModal from "./DeleteMeetingModal";
import ViewMinutes from "./ViewMinutes";
import MeetingAttendance from "../Meeting/MeetingAttendance";
import ViewParentActions from "./ViewParentActions";
import MOMAcceptanceDetails from "./MOMAcceptanceDetails";
import { logOut } from "../../redux/actions/authActions/authAction";
import { ToastContainer, toast } from "react-toastify";
//import AddMinuteAgendaModal from "./AddMinuteAgendaModal";

const ViewMinutePage = (props) => {
  let { meetingId } = useParams();
  const location = useLocation();
  const stateData = location.state;
  console.log(stateData);
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (authData.isInValidUser) {
    console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnn");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }

  const employeeData = useSelector((state) => state.user);
  const meetingRoomData = useSelector((state) => state.meetingRoom);
  const minuteData = useSelector((state) => state.minute);
  const meetingData = useSelector((state) => state.meeting);
  const [isMinutesRsvpStatusModal, setIsMinutesRsvpStatusModal] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attendeesData, setAttendeesData] = useState([]);
  const [isDeleteMinuteModal, setIsDeleteMinuteModal] = useState(false);
  const [isEditMinuteModal, setIsEditMinuteModal] = useState(false);
  const [minuteDetails, setMinuteDetails] = useState(null);
  const [agendaData, setAgendaData] = useState(null);
  const [amendmentModal, setAmendmentModal] = useState(false);
  const [createdById, setCreatedById] = useState(null);
  const [details, setDetails] = useState(null);
  const [amendmentViewModal, setAmendmentViewModal] = useState(false);
  const [isDeleteAgendaModal, setIsDeleteAgendaModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [minuteId, setMinuteId] = useState(null);

  console.log("use effect------------------------------------");
  useEffect(() => {
    document.title = "View Minutes: MinutesVault";
    console.log("use effect------------------------------------");
    console.log(stateData);
    // dispatch(getSingleMeetingDetails(stateData.meetingId, accessToken));
    // dispatch()
    if (meetingId && accessToken) {
      dispatch(getAgendaWithMinutesDetails(meetingId, accessToken));
    }
    return () => {
      console.log("return useeffect--------------->>>>>>>>>>>>>>");
      // dispatch(unSetSingleMeetingDetails);
    };
  }, []);
  console.log(employeeData);

  console.log(meetingData);

  // if(meetingData.singleMeetingDetails && employeeData?.userData){
  //   if (
  //     (meetingData.singleMeetingDetails.createdById===employeeData?.userData?._id.toString() &&
  //       ((employeeData?.userData?.isMeetingOrganiser === true &&
  //         employeeData?.userData?.isAdmin === false) ||
  //         (employeeData?.userData?.isMeetingOrganiser === false &&
  //           employeeData?.userData?.isAdmin === true) ||
  //         (employeeData?.userData?.isMeetingOrganiser === true &&
  //           employeeData?.userData?.isAdmin === true) ||
  //         meetingData?.meetingDetails?.canWriteMOM === true)) ||
  //         (meetingData.singleMeetingDetails.createdByIdd===employeeData?.userData?._id.toString() &&
  //       ((employeeData?.userData?.isMeetingOrganiser === true &&
  //         employeeData?.userData?.isAdmin === false) ||
  //         (employeeData?.userData?.isMeetingOrganiser === false &&
  //           employeeData?.userData?.isAdmin === true) ||
  //         (employeeData?.userData?.isMeetingOrganiser === true &&
  //           employeeData?.userData?.isAdmin === true)) ) ||
  //     (meetingData.singleMeetingDetails.createdById===employeeData?.userData?._id.toString() &&
  //       ((employeeData?.userData?.isMeetingOrganiser === true &&
  //         employeeData?.userData?.isAdmin === false) ||
  //         (employeeData?.userData?.isMeetingOrganiser === false &&
  //           employeeData?.userData?.isAdmin === true) ||
  //         (employeeData?.userData?.isMeetingOrganiser === true &&
  //           employeeData?.userData?.isAdmin === true) )
  //       )
  //   ) {
  //     console.log("is allowed");
  //     // alert("inside if")
  //   } else {
  //     // alert("inside else")
  //     console.log("blocked");
  //     console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnn");
  //     if(accessToken){
  //     localStorage.removeItem("accessToken");
  //     localStorage.removeItem("userData");
  //     localStorage.removeItem("rememberMe");
  //     // dispatch(logOut());
  //     // navigate("/login");
  //     toast.error("Sorry. You do not have permission for this page.", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //       // transition: Bounce,
  //     });
  //   }
  //   }
  // }

  console.log(meetingData.meetingDetails);
  return (
    <>
      <Header />
      <Sidebar />
      {meetingData?.meetingDetails !== null ? (
        <div className="main-content">
          <div className="row">
            {/* {meetingData?.meetingDetails!==null?( */}
            <>
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
                <ViewMinutes meetingId={meetingId} />
                <form className="mt-4">
                  <ViewParentActions
                    parentMeetingId={
                      meetingData?.meetingDetails?.parentMeetingId
                    }
                    isHideOptions={true}
                  />
                </form>
              </div>
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
                <div className="meeting-header-text">
                  {meetingId ? <h4>&nbsp;</h4> : <h4>Meeting Preview</h4>}

                  {meetingId &&
                  meetingData?.meetingDetails?.momGenerationDetails?.length !==
                    0 ? (
                    <div className="">
                      <div className="row">
                        <div className="col">
                          <div className="d-inline-block">
                            {meetingData?.loading ? (
                              <LoaderButton />
                            ) : (
                              <button
                                className="Mom-btn"
                                onClick={() => {
                                  dispatch(downloadMOM(meetingId, accessToken));
                                }}
                              >
                                <p>Download MOM</p>
                              </button>
                            )}
                            {/* <div aria-labelledby="dropdownBasic1">
                  <button>Save Meeting</button>
                  <button>Save Meeting and Notification</button>
                </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <form className="mt-2 details-form details-form-right">
                  <MeetingAttendance />
                </form>
                {minuteData?.momAcceptDetails?.length !== 0 ? (
                  <form className="mt-4 details-form details-form-right">
                    <MOMAcceptanceDetails meetingId={meetingId} />
                  </form>
                ) : null}
                {employeeData.userData.isMeetingOrganiser ||
                employeeData.userData.isAdmin ? (
                  <form className="mt-4 details-form details-form-right">
                    <MeetingActivities meetingId={meetingId} />
                  </form>
                ) : null}
              </div>
            </>
            {/* )
        :
        <NoDataFound />
        } */}
          </div>
        </div>
      ) : meetingData?.meetingDetails === null && !meetingData.loading && !meetingData.isSuccess ? (
        <div className="main-content">
          <div className="row">
            <NoDataFound />
          </div>
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </>
  );
};

export default ViewMinutePage;
