import Header from "../Common/Header/Header";
import MeetingHeader from "../Common/Header/MeetingHeader";
import Sidebar from "../Common/Sidebar/Sidebar";
import React, { useState, useEffect } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useNavigate, Navigate, Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getAgendaWithMinutesDetails,
  getCreateMeetingStep,
  getSingleMeetingAgendaFullDetails,
  getSingleMeetingDetails,
  unSetSingleMeetingDetails,
  updateStep,
} from "../../redux/actions/meetingActions/MeetingAction";
// import CreateMinutes from "./CreateMinutes";
import ViewMinutes from "./ViewMinutes";
import { useLocation } from "react-router-dom";
import "./minutes.css";
import CreateMinutes from "./CreateMinutes";
import { logOut } from "../../redux/actions/authActions/authAction";
import ViewParentActions from "./ViewParentActions";
import { ToastContainer, toast } from "react-toastify";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import { combineDateAndTime } from "../../helpers/commonHelpers";
import Alert from "../Common/Alert";

const MinutesPage = () => {
  let { meetingId } = useParams();
  const dispatch = useDispatch();
  
  const employeeData = useSelector((state) => state.user);
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser) {
    console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnn");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const meetingData = useSelector((state) => state.meeting);


  const location = useLocation();
  const stateData = location.state;
  console.log(stateData);

  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const meetingRoomData = useSelector((state) => state.meetingRoom);

  const minuteData = useSelector((state) => state.minute);
  const [openAgendaForm, setOpenAgendaForm] = useState(false);
  const [trigger, setTrigger] = useState(false);
  console.log(minuteData);
  console.log("use effect------------------------------------");
  console.log(employeeData?.userData?.isMeetingOrganiser);


  useEffect(() => {
    console.log("use effect-------------------------------22-----");
    console.log(stateData);
    if (accessToken) {
      dispatch(getAgendaWithMinutesDetails(meetingId, accessToken));
    }
    // dispatch()
    return () => {
      console.log("return useeffect--------------->>>>>>>>>>>>>>");
      // dispatch(unSetSingleMeetingDetails);
    };
  }, [minuteData.isSuccess]);
  if(meetingData?.meetingDetails){
    const meetingDateTime = combineDateAndTime(
      new Date(meetingData?.meetingDetails?.actualDate),
      meetingData?.meetingDetails?.actualFromTime
    ).getTime();
    console.log("meetingDateTime---------", meetingDateTime);
    const currentDateTime = new Date().getTime();
    console.log("currentDateTime---------", currentDateTime);
    const isWriteMinuteAllowed = meetingDateTime <= currentDateTime ? true : false;
    console.log("isWriteMinuteAllowed---------", isWriteMinuteAllowed);
    // if (isWriteMinuteAllowed===false) {
    //   console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnn");
    //   toast.error("Sorry! Write minute process yet to be started!", {
    //     position: "top-right",
    //     autoClose: 3000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //   });
    //   navigate("/meeting-list");
    // }
  
  }
  
  console.log(meetingData.meetingDetails);
  console.log(employeeData);
  console.log(employeeData?.userData?.isMeetingOrganiser);
  console.log(employeeData?.userData?.isAdmin);
  console.log(meetingData.meetingDetails?.canWriteMOM);
  console.log(meetingData?.meetingDetails?.meetingStatus?.status);
  const isAttendee = meetingData?.meetingDetails?.attendees?.find(
    (item) => item?._id?.toString() === employeeData?.userData?._id?.toString()
  );
  console.log("isAttendee----------------", isAttendee);
// if(meetingData?.meetingDetails && employeeData?.userData){
//   if (
//     (isAttendee && meetingData?.meetingDetails?.meetingStatus?.status === "scheduled" &&
//       ((employeeData?.userData?.isMeetingOrganiser === true &&
//         employeeData?.userData?.isAdmin === false) ||
//         (employeeData?.userData?.isMeetingOrganiser === false &&
//           employeeData?.userData?.isAdmin === true) ||
//         (employeeData?.userData?.isMeetingOrganiser === true &&
//           employeeData?.userData?.isAdmin === true) ||
//         meetingData?.meetingDetails?.canWriteMOM === true)) ||
//     (isAttendee && meetingData?.meetingDetails?.meetingStatus?.status === "rescheduled" &&
//       ((employeeData?.userData?.isMeetingOrganiser === true &&
//         employeeData?.userData?.isAdmin === false) ||
//         (employeeData?.userData?.isMeetingOrganiser === false &&
//           employeeData?.userData?.isAdmin === true) ||
//         (employeeData?.userData?.isMeetingOrganiser === true &&
//           employeeData?.userData?.isAdmin === true) ||
//         meetingData?.meetingDetails?.canWriteMOM === true)) ||
//     (isAttendee && meetingData?.meetingDetails?.meetingStatus?.status === "closed" &&
//       meetingData?.meetingDetails?.parentMeetingDetails?.length === 0 &&
//       ((employeeData?.userData?.isMeetingOrganiser === true &&
//         employeeData?.userData?.isAdmin === false) ||
//         (employeeData?.userData?.isMeetingOrganiser === false &&
//           employeeData?.userData?.isAdmin === true) ||
//         (employeeData?.userData?.isMeetingOrganiser === true &&
//           employeeData?.userData?.isAdmin === true) ||
//         meetingData?.meetingDetails?.canWriteMOM === true))
//   ) {
//     console.log("is allowed");
//     // alert("inside if")
//   } else {
//     // alert("inside else")
//     console.log("blocked");
//     console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnn");
//     if(accessToken){
//     localStorage.removeItem("accessToken");
//     localStorage.removeItem("userData");
//     localStorage.removeItem("rememberMe");
//     dispatch(logOut());
//     navigate("/login");
//     toast.error("Sorry. You do not have permission for this page.", {
//       position: "top-right",
//       autoClose: 3000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "colored",
//       // transition: Bounce,
//     });
//   }
//   }
// }

  const openAgenda = () => {
    setOpenAgendaForm(!openAgendaForm);
  };

  const closeAgenda = () => {
    setOpenAgendaForm(false);
  };
  console.log("meetingData------------------------", meetingData);

 
  return (
    <>
      <Header />
      <Sidebar />
      {meetingData?.meetingDetails!==null?(
      <div className="main-content">
        <div className="row">
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
            <CreateMinutes
              openAgendaForm={openAgendaForm}
              closeAgendaForm={closeAgenda}
            />
            <form className="mt-4">
              <ViewParentActions
                parentMeetingId={meetingData?.meetingDetails?.parentMeetingId}
              />
            </form>
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
            <ViewMinutes openAgendaForm={openAgenda} inWriteMinute={true}/>
          </div>
        </div>
      </div>
    ) : meetingData?.meetingDetails === null && !meetingData?.loading && !meetingData.isSuccess ? (
        <div className="main-content">
           <div className="meeting-header-text">
                <h4>Write Minutes</h4>
              </div>
          <div className="row">
          <form className="mt-2 details-form details-form-right">
                <div className="row">
                <Alert
                status={"info"}
                message={"No data available."}
                timeoutSeconds={0}
              />
                <NoDataFound dataType={"meeting"} />
                </div>
              </form>
          </div>
        </div>
      ) : (
        <div>
           <div className="meeting-header-text">
                <h4>Write Minutes</h4>
              </div>
          <Loader />
        </div>
      )}
    </>
  );
};

export default MinutesPage;
