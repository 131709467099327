import React, { useState, useEffect } from "react";
import Header from "../Common/Header/Header";
import Sidebar from "../Common/Sidebar/Sidebar";
// @ts-ignore
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useNavigate, Navigate, Link } from "react-router-dom";
import MeetingHeader from "../Common/Header/MeetingHeader";
//import ButtonToolbar from 'react-bootstrap/ButtonGroup';
import Button from "react-bootstrap/Button";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { useSelector, useDispatch } from "react-redux";
import "./style/meetings-css.css";
import {
  fetchMeetingList,
  processCancelMeeting,
  processCloseMeeting,
  updateIsSuccess,
  updateRsvp,
  viewMeetingStatistics,
} from "../../redux/actions/meetingActions/MeetingAction";
import LoaderButton from "../Common/LoaderButton";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";
import MeetingDropDown from "./MeetingDropDown";
import FilterComponent from "./FilterComponent";
import AttendeesModal from "./AttendeesModal";
import { combineDateAndTime, customName } from "../../helpers/commonHelpers";
import NoDataFound from "../Common/NoDataFound";
import AttendeesRsvpModal from "./AttendeesRsvpModal";
import CommonModal from "../Common/CommonModal";
import { cancelMeeting } from "../../constants/constatntMessages";
import CancelMeetingModal from "./CancelMeetingModal";
import { logOut } from "../../redux/actions/authActions/authAction";
import RescheduleMeetingModal from "./RescheduleMeetingModal";
import Footer from "../Common/Footer/Footer";
const MeetingList = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const meetingData = useSelector((state) => state.meeting);
  const loginUserData = useSelector((state) => state.user);

  const employeeData = useSelector((state) => state.user);
  console.log(employeeData);
  if (authData.isInValidUser) {
    console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnn");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const [filter, setfilter] = useState(false);
  const [meetingId, setMeetingId] = useState(null);
  const [rsvpCount, setRsvpCount] = useState("");
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [isRsvpModalOpen, setIsRsvpModalOpen] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [optionArray, setOptionArray] = useState(false);
  const [attendeesData, setAttendeesData] = useState([]);

  const [meetingDetails, setMeetingDetails] = useState(null);
  const [singleMeetingDetails, setSingleMeetingDetails] = useState(null);

  const [searchData, setSearchData] = useState({
    searchKey: "",
    page: 1,
    limit: 5,
    order: -1,
    filterData: {},
  });

  const filterData = (data) => {
    console.log(data);
    setSearchData({
      ...searchData,
      filterData: data,
    });
  };

  const setModalStatus = (value, meeting) => {
    if (meeting?.attendees?.length !== 0) {
      setIsModalOpen(value);
      setAttendeesData([...meeting?.attendees]);
      setMeetingDetails(meeting);
      // setIsUser(isUser)
    }
  };

  const setRsvpModalStatus = (value, attendeesData, rsvpCount) => {
    setIsRsvpModalOpen(value);
    setAttendeesData([...attendeesData]);
    setRsvpCount(rsvpCount);
    // setIsUser(isUser)
  };

  useEffect(() => {
    document.title = "Meeting List: MinutesVault";
    console.log("userData------------", userData);
    dispatch(viewMeetingStatistics(userData?.organizationId, accessToken));
    if (!accessToken) {
      navigate("/login");
    }
    if (!userData) {
      navigate("/login");
    } else {
      console.log("userData------------", userData);
      //   console.log("repeat------------------------", searchData);
      const payload = {
        page: searchData.page,
        order: searchData.order,
        limit: searchData.limit,
        organizationId: userData.organizationId,
        meetingStatus: searchData.filterData?.meetingStatus,
        toDate: searchData.filterData?.toDate,
        fromDate: searchData.filterData?.fromDate,
        attendeeId: searchData.filterData?.attendeeId,
        accessToken,
      };
      if (searchData.searchKey !== "") {
        payload["searchKey"] = searchData.searchKey;
        setSearchData({
          ...searchData,
          page: 1,
        });
        payload.page = 1;
      }
      if (searchData.filterData.page === 1) {
        payload["searchKey"] = searchData.searchKey;
        setSearchData({
          ...searchData,
          page: 1,
        });
        payload.page = 1;
      }
      // console.log("payload in meetinglist----------34-------", payload);
      if (!authData.isInValidUser) {
        dispatch(fetchMeetingList(payload));
      }
    }

    if (meetingData.isFetchedMeetingList) {
      setIsModalOpen(false);
    }
  }, [
    searchData.searchKey,
    searchData.order,
    searchData.page,
    searchData.limit,
    searchData.filterData,
    meetingData.isRsvpUpdated,
    meetingData.isFetchedMeetingList,
  ]);
  console.log(
    "meetingData---------------------->>>>>>>>>>>>>>>>>>>>>>>",
    meetingData
  );
  const handleCancelMeeting = (remarks) => {
    console.log("ccccccccccc", meetingId, remarks);

    dispatch(processCancelMeeting(meetingId, { remarks }, accessToken));
    setMeetingId(null);
    setIsCancelModalOpen(false);
  };
  console.log(meetingId);
  const handleCloseMeeting = () => {
    console.log("ccccccccccc", meetingId);

    dispatch(
      processCloseMeeting(meetingId, { meetingStatus: "closed" }, accessToken)
    );
    setMeetingId(null);
    setIsCloseModalOpen(false);
  };
  const handleCancelModal = (meetingId) => {
    console.log("gggggggggggggg", meetingId);
    setMeetingId(meetingId);
    setIsCancelModalOpen(true);
  };

  const handleCloseModal = (meetingId) => {
    console.log("gggggggggggggg", meetingId);
    setMeetingId(meetingId);
    setIsCloseModalOpen(true);
  };

  const handleRescheduleModal = (singleMeetingDetails) => {
    console.log("gggggggggggggg22", singleMeetingDetails);
    setSingleMeetingDetails(singleMeetingDetails);

    setIsRescheduleModalOpen(true);
  };
  const handlePageChange = (event, value) => {
    console.log(value);
    setSearchData({
      ...searchData,
      page: value,
    });
  };
  const handleChange = (e) => {
    // console.log("on change------------------->>>>>>", e.target);
    const { name, value } = e.target;
    setSearchData({
      ...searchData,
      [name]: value,
    });

    // const payload = {
    //   page: searchData.page,
    //   order: searchData.order,
    //   limit: searchData.limit,
    //   organizationId: userData.organizationId,
    // };
    // if (searchData.searchKey !== "") {
    //   payload["searchKey"] = searchData.searchKey;
    // }
    // console.log("payload in meetinglist-------------12----", payload);
    // // dispatch(fetchMeetingList(payload));
  };
  console.log(
    "->>>>>>>66666666666666666----------------isModalOpen",
    isModalOpen
  );
  console.log(meetingData.totalCount % 5);

  const totalOption =
    meetingData?.totalCount && meetingData?.totalCount % 5 === 0
      ? Math.round(meetingData.totalCount / 5)
      : meetingData?.totalCount && meetingData?.totalCount % 5 !== 0
      ? Math.round(meetingData.totalCount / 5 + 0.5)
      : 0;
  console.log(totalOption);
  const checkTotalPage =
    meetingData.totalCount % searchData.limit === 0
      ? Math.round(meetingData.totalCount / searchData.limit)
      : Math.round(meetingData.totalCount / searchData.limit + 0.5);
  console.log(checkTotalPage);
  const totalPage = meetingData.totalCount ? checkTotalPage : 0;
  console.log(Math.round(meetingData.totalCount / searchData.limit));
  console.log(totalPage);
  console.log(Math.round(meetingData.totalCount / searchData.limit + 0.5));
  const totalPageArray = Array(totalPage).fill();
  console.log(totalPageArray);
  let modifiedArray = [];
  totalPageArray.map((item, index) => {
    if (
      index === searchData.page - 1 ||
      (index <= searchData.page + 3 && index >= searchData.page)
    ) {
      console.log(index === searchData.page - 1);
      console.log(index <= searchData.page + 3 && index >= searchData.page);
      console.log(index);
      modifiedArray.push(index);
    }
    return item;
  });
  console.log(modifiedArray);
  // console.log(
  //   "totalOption-------------------->",
  //   meetingData.totalCount / searchData.limit + 0.5,
  //   totalOption
  // );
  //const fromDataCount= searchData.page===1?searchData.limit+meetingData.meetingList.length
  const fromDataCount =
    meetingData.meetingList?.length === 0
      ? 0
      : (searchData.page - 1) * searchData.limit + 1;
  const toDataCount =
    (searchData.page - 1) * searchData.limit + meetingData.meetingList?.length;
  //searchData.limit+meetingData.meetingList.length
  const totalCount = meetingData.totalCount;
  console.log(fromDataCount, toDataCount, totalCount);

  const checkRsvpCount = (attendees) => {
    let yesCount = 0;
    let noCount = 0;
    let pendingCount = 0;
    let mayBeCount = 0;

    attendees.map((item) => {
      item.rsvp === "YES"
        ? (yesCount = yesCount + 1)
        : item.rsvp === "NO"
        ? (noCount = noCount + 1)
        : item.rsvp === "MAYBE"
        ? (mayBeCount = mayBeCount + 1)
        : (pendingCount = pendingCount + 1);
    });

    const countMessage = `${yesCount} Yes, ${noCount} No, ${mayBeCount} May Be, ${pendingCount} Awaiting`;

    return countMessage;
  };

  const checkRsvpOption=async (meetingDetails)=>{
  
  const meetingDateTime = combineDateAndTime(
    new Date(meetingDetails?.actualDate),
    meetingDetails?.actualFromTime
  ).getTime()
  console.log("meetingDateTime---------", meetingDateTime);
  const currentDateTime = new Date().getTime();
  console.log("currentDateTime---------", currentDateTime);
  const isWriteMinuteAllowed = meetingDateTime <= currentDateTime ? true : false;
  console.log("isWriteMinuteAllowed---------", isWriteMinuteAllowed);
  return isWriteMinuteAllowed;
  }

  console.log( meetingData?.meetingStatisticsData);
  return (
    <>
      <Header />
      <Sidebar />
      {!accessToken ? <Navigate to="/login" /> : null}
      <div className="main-content">
        <div className="meeting-page ">
          <div className="meeting-header-text">
            <div>
              <h4>Meetings</h4>
            </div>
            <div>
              <button
                className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
                id="open-form-btn"
                onClick={(e) => setfilter(true)}
              >
                <p> Filter</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-funnel filter-svg"
                  viewBox="0 0 16 16"
                >
                  <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                </svg>
              </button>
            </div>
          </div>

          {/* {filter ? (
            <FilterComponent
              setfilter={setfilter}
              filterData={filterData}
              initData={searchData.filterData}
              filter={filter}
            />
          ) : null} */}

          <FilterComponent
            setfilter={setfilter}
            filterData={filterData}
            initData={searchData.filterData}
            filter={filter}
          />
        </div>

        {/* {meetingData?.meetingStatisticsData && !meetingData.statisticLoading ? ( */}
          <div className="color-box-resp">
            <div className="row mt-3">
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="panel-db panel to-cyan-400 ">
                  <div className="flex justify-between">
                    <div className="ltr:mr-1 rtl:ml-1 text-md font-semibold font-size-15">
                      Upcoming Meetings
                    </div>
                  </div>
                  <div className="flex items-center mt-3">
                    <div className="text-3xl font-bold ">
                      {" "}
                      {meetingData?.meetingStatisticsData && !meetingData.statisticLoading ?meetingData?.meetingStatisticsData?.upcommingMeetings:0}
                    </div>
                  </div>
                  <div className="flex items-center font-semibold mt-3 font-size-15 responsive-flex">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2 shrink-0"
                    >
                      <path
                        opacity="0.5"
                        d="M3.27489 15.2957C2.42496 14.1915 2 13.6394 2 12C2 10.3606 2.42496 9.80853 3.27489 8.70433C4.97196 6.49956 7.81811 4 12 4C16.1819 4 19.028 6.49956 20.7251 8.70433C21.575 9.80853 22 10.3606 22 12C22 13.6394 21.575 14.1915 20.7251 15.2957C19.028 17.5004 16.1819 20 12 20C7.81811 20 4.97196 17.5004 3.27489 15.2957Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                      <path
                        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                    </svg>
                    Total {meetingData?.meetingStatisticsData && !meetingData.statisticLoading ?meetingData?.meetingStatisticsData?.totalMeetings:0}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="panel-db panel to-violet-400">
                  <div className="flex justify-between">
                    <div className="ltr:mr-1 rtl:ml-1 text-md font-semibold font-size-15">
                      Total Due Actions
                    </div>
                  </div>
                  <div className="flex items-center mt-3">
                    <div className="text-3xl font-bold ">
                      {" "}
                      { meetingData?.meetingStatisticsData && !meetingData.statisticLoading ?
                        meetingData?.meetingStatisticsData
                          ?.totalHighPriorityDueAction:0
                      }{" "}
                      / {meetingData?.meetingStatisticsData && !meetingData.statisticLoading ? meetingData?.meetingStatisticsData?.totalDueAction:0}
                    </div>
                  </div>
                  <div className="flex items-center font-semibold mt-3 font-size-15 responsive-flex">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2 shrink-0"
                    >
                      <path
                        opacity="0.5"
                        d="M3.27489 15.2957C2.42496 14.1915 2 13.6394 2 12C2 10.3606 2.42496 9.80853 3.27489 8.70433C4.97196 6.49956 7.81811 4 12 4C16.1819 4 19.028 6.49956 20.7251 8.70433C21.575 9.80853 22 10.3606 22 12C22 13.6394 21.575 14.1915 20.7251 15.2957C19.028 17.5004 16.1819 20 12 20C7.81811 20 4.97196 17.5004 3.27489 15.2957Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                      <path
                        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                    </svg>
                    High priority
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="panel-db panel to-blue-400">
                  <div className="flex justify-between">
                    <div className="ltr:mr-1 rtl:ml-1 text-md font-semibold font-size-15">
                      Total Due Actions
                    </div>
                  </div>
                  <div className="flex items-center mt-3">
                    <div className="text-3xl font-bold ">
                      {
                       meetingData?.meetingStatisticsData && !meetingData.statisticLoading ? meetingData?.meetingStatisticsData
                          ?.totalMediumPriorityDueAction:0
                      }{" "}
                      / {meetingData?.meetingStatisticsData && !meetingData.statisticLoading ? meetingData?.meetingStatisticsData?.totalDueAction:0}
                    </div>
                  </div>
                  <div className="flex items-center font-semibold mt-3 font-size-15 responsive-flex">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2 shrink-0"
                    >
                      <path
                        opacity="0.5"
                        d="M3.27489 15.2957C2.42496 14.1915 2 13.6394 2 12C2 10.3606 2.42496 9.80853 3.27489 8.70433C4.97196 6.49956 7.81811 4 12 4C16.1819 4 19.028 6.49956 20.7251 8.70433C21.575 9.80853 22 10.3606 22 12C22 13.6394 21.575 14.1915 20.7251 15.2957C19.028 17.5004 16.1819 20 12 20C7.81811 20 4.97196 17.5004 3.27489 15.2957Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                      <path
                        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                    </svg>
                    Normal priority
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="panel-db panel to-fuchsia-400">
                  <div className="flex justify-between">
                    <div className="ltr:mr-1 rtl:ml-1 text-md font-semibold font-size-15">
                      Total Due Actions
                    </div>
                  </div>
                  <div className="flex items-center mt-3">
                    <div className="text-3xl font-bold ">
                      {
                        meetingData?.meetingStatisticsData && !meetingData.statisticLoading ?meetingData?.meetingStatisticsData
                          ?.totalLowPriorityDueAction:0
                      }{" "}
                      / {meetingData?.meetingStatisticsData && !meetingData.statisticLoading ?meetingData?.meetingStatisticsData?.totalDueAction:0}
                    </div>
                  </div>
                  <div className="flex items-center font-semibold mt-3 font-size-15 responsive-flex">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2 shrink-0"
                    >
                      <path
                        opacity="0.5"
                        d="M3.27489 15.2957C2.42496 14.1915 2 13.6394 2 12C2 10.3606 2.42496 9.80853 3.27489 8.70433C4.97196 6.49956 7.81811 4 12 4C16.1819 4 19.028 6.49956 20.7251 8.70433C21.575 9.80853 22 10.3606 22 12C22 13.6394 21.575 14.1915 20.7251 15.2957C19.028 17.5004 16.1819 20 12 20C7.81811 20 4.97196 17.5004 3.27489 15.2957Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                      <path
                        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                    </svg>
                    Low priority
                  </div>
                </div>
              </div>
            </div>
          </div>
      
        <div className="mt-2 table-box responsive-table">
          <div className="tbl-text-search">
            <div className="left-tbl-text">
              {totalCount > 0 ? (
                <p>
                  Showing {fromDataCount} to {toDataCount} of {totalCount}{" "}
                  entries
                </p>
              ) : null}
            </div>
            <div className="search-box">
              <input
                type="search"
                placeholder="Search By Meeting Title / Meeting ID"
                onChange={handleChange}
                name="searchKey"
                value={searchData.searchKey}
                autoComplete="off"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#4F2CC8"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </div>
          </div>
          {!meetingData.loading &&
          meetingData.meetingList?.length !== 0 &&
          meetingData.isSuccess ? (
            // <div className="table-responsive">
            <table className="mt-4 table table-bordered table-striped">
              <thead>
                <tr>
                  {/* <th scope="col">Meeting Id</th> */}
                  <th scope="col">Meeting Date & Time</th>
                  <th scope="col">Meeting Title & ID</th>
                  <th scope="col">Attendees</th>
                  <th scope="col">RSVP Confirmation</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action Due</th>
                  <th scope="col" className="action-col">
                    Action
                  </th>
                </tr>
              </thead>

              <tbody>
                {meetingData?.meetingList?.map((meeting, index) => {
                  return (
                    <tr>
                      {/* <td data-label="Meeting Date & Time">
                        {meeting?.meetingId}

                     
                      </td> */}
                      <td
                        data-label="Meeting Date & Time"
                        className="meeting-td-width-1"
                      >
                        {meeting?.date}
                        <p className="detail-date-time">
                          {meeting?.fromTime}
                          {/* {formatDateTimeFormat(meeting.date).formattedTime} */}
                        </p>
                      </td>
                      <td
                        data-label="Meeting Title"
                        className="meeting-td-width-2 word-break"
                      >
                        {meeting.title}

                        <p className="detail-date-time">
                          Meeting ID - {meeting?.meetingId}
                          {/* {formatDateTimeFormat(meeting.date).formattedTime} */}
                        </p>
                        {/* ({meeting?.meetingId}) */}
                        {meeting.attendees?.length !== 0 &&
                        meeting.attendees?.find(
                          (item) =>
                            item?._id?.toString() ===
                            employeeData?.userData?._id?.toString()
                        ) &&
                        // employeeData?.userData.isMeetingOrganiser === false &&
                        // employeeData?.userData.isAdmin === false &&
                        meeting?.canUpdateRSVP===true  ? (
                          <div className="respond-button">
                            {meeting.rsvp === "YES" ? (
                              <button disabled className="respond-action">
                                {" "}
                                <>&#x2713; </>Yes
                              </button>
                            ) : (
                              <button
                                className="respond-action"
                                onClick={() => {
                                  dispatch(updateRsvp("YES", meeting._id));
                                }}
                              >
                                {" "}
                                Yes
                              </button>
                            )}

                            {meeting.rsvp === "NO" ? (
                              <button disabled className="respond-action">
                                {" "}
                                <>&#x2713; </>No
                              </button>
                            ) : (
                              <button
                                className="respond-action"
                                onClick={() => {
                                  dispatch(updateRsvp("NO", meeting._id));
                                }}
                              >
                                {" "}
                                No
                              </button>
                            )}

                            {meeting.rsvp === "MAYBE" ? (
                              <button disabled className="respond-action">
                                {" "}
                                <>&#x2713; </>May Be
                              </button>
                            ) : (
                              <button
                                className="respond-action"
                                onClick={() => {
                                  dispatch(updateRsvp("MAYBE", meeting._id));
                                }}
                              >
                                {" "}
                                May Be
                              </button>
                            )}
                          </div>
                        ) : null}
                      </td>
                      <td
                        data-label="Attendees"
                        className="cursor-pointer minHeight-for-noData"
                        onClick={(e) => {
                          setModalStatus(true, meeting);
                        }}
                      >
                        <div className="attendees">
                          {meeting?.attendees &&
                            meeting?.attendees
                              .filter((item, index) => index < 5)
                              .map((attendee, index) => {
                                return (
                                  <div className="attendee-list">
                                    {customName(attendee?.name)}
                                  </div>
                                );
                              })}
                        </div>
                        <p className="plus-more-text m-0">
                          {meeting?.attendees?.length > 5
                            ? `+${meeting?.attendees?.length - 5} More`
                            : null}
                        </p>
                      </td>
                      <td
                        className="meeting-td-width meeting-td-width-4"
                        data-label="RSVP Confirmation"
                        onClick={(e) => {
                          setRsvpModalStatus(
                            true,
                            meeting.attendees,
                            checkRsvpCount(meeting.attendees)
                          );
                        }}
                      >
                        <p>{meeting.attendees.length} Attendees</p>
                        <p className="detail-date-time">
                          {checkRsvpCount(meeting.attendees)}
                        </p>
                      </td>
                      <td data-label="Status" className="meeting-td-width-5">
                        <span
                          className={
                            meeting?.meetingStatus?.status === "scheduled" ||
                            meeting?.meetingStatus?.status === "rescheduled"
                              ? "badge bg-success bg-opacity-10 "
                              : meeting?.meetingStatus?.status === "closed"
                              ? "badge bg-primary bg-opacity-10 "
                              : meeting?.meetingStatus?.status === "draft"
                              ? "badge bg-secondary bg-opacity-10 "
                              : "badge bg-danger bg-opacity-10 "
                          }
                        >
                          {meeting?.meetingStatus?.status
                            .charAt(0)
                            .toUpperCase() +
                            meeting?.meetingStatus?.status.slice(1)}
                        </span>
                        {/* <Button variant="success"  size="sm" style={{fontWeight:"bold"}} >Scheduled</Button> */}
                      </td>
                      <td
                        data-label="Action Due"
                        className="meeting-td-width-6"
                      >
                        {
                          meeting?.actionDetail.filter(
                            (item) => item?.isComplete
                          ).length
                        }
                        /
                        {
                          meeting?.actionDetail.filter((item) => item?.isAction)
                            .length
                        }
                      </td>
                      <td
                        data-label="Action"
                        className="text-align-center meeting-td-width-7"
                      >
                        <div className="d-inline-block menu-dropdown custom-dropdown">
                          <Dropdown>
                            <MeetingDropDown
                              meetingDetails={meeting}
                              meetingId={meeting._id}
                              status={meeting.meetingStatus.status}
                              handleCancelModal={(meetingId) => {
                                console.log("yes");
                                handleCancelModal(meeting._id);
                              }}
                              handleRescheduleModal={() => {
                                handleRescheduleModal(meeting);
                              }}
                              handleCloseModal={() => {
                                handleCloseModal(meeting._id);
                              }}
                            />
                          </Dropdown>

                          {/* {buttonStatus ? <VariantsExample /> : null} */}

                          {/* <div
                            aria-labelledby="dropdownBasic1"
                            className="list-dropdown"
                          >
                            <div className="dropdown-divider"></div>
                          </div> */}
                        </div>
                      </td>
                    </tr>
                  );
                })}

                <AttendeesModal
                  IsModalOpen={isModalOpen}
                  attendees={attendeesData}
                  setIsModalOpen={setIsModalOpen}
                  loginUserData={loginUserData}
                  isShow={true}
                  meetingData={meetingDetails}
                  // attendeeData={meeting.attendees}
                />
                <AttendeesRsvpModal
                  IsRsvpModalOpen={isRsvpModalOpen}
                  attendees={attendeesData}
                  setIsRsvpModalOpen={setIsRsvpModalOpen}
                  loginUserData={loginUserData}
                  rsvpCount={rsvpCount}

                  // attendeeData={meeting.attendees}
                />
              </tbody>
            </table>
          ) : // </div>
          !meetingData.loading && meetingData.meetingList?.length === 0 && !meetingData.isSuccess ? (
            <div className="no-data-img">
              <Alert
                status={"info"}
                message={"No data available."}
                timeoutSeconds={0}
              />
              {/* <Alert message="No Data Found !" status={false} /> */}

              <NoDataFound dataType={"meeting"} />
              <Button
                className="mt-2"
                variant="primary"
                onClick={(e) => {
                  setSearchData({
                    ...searchData,
                    searchKey: "",
                    filterData: {},
                    page: 1,
                  });
                }}
              >
                Clear
              </Button>
            </div>
          ) : (
            <div
              className="meeting-page "
              style={{ textAlign: "center", paddingTop: 20 }}
            >
              <Loader />
            </div>
          )}
          {totalCount <= 1 ? null : (
            <div className="tbl-bottom">
              <div className="left-tbl-bottom">
                {searchData.page !== 1 ? (
                  <button
                    className="left-arrow"
                    onClick={(e) =>
                      setSearchData({
                        ...searchData,
                        page: searchData.page - 1,
                      })
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#fff"
                      className="bi bi-chevron-left"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                      />
                    </svg>
                  </button>
                ) : null}
                {/* <ul>
                  {totalPageArray?.length &&
                    totalPageArray.map((_, index) => {
                      // console.log("option-----------------------", option);
                      console.log("index-----22----------------------", index);
                      //  if((index===searchData.page-1 ) ||(index<=searchData.page+3 && index>=searchData.page)){
                      if (
                        index === 0 ||
                        (index <= searchData.page + 2 &&
                          index >= searchData.page) ||
                        index === totalPageArray.length - 1 ||
                        index === searchData.page - 1 ||
                        (index >= searchData.page - 2 &&
                          index >= searchData.page)
                      ) {
                        console.log(
                          "page---33------------------------",
                          searchData.page
                        );
                        console.log(
                          "index---33------------------------",
                          index
                        );
                        return (
                          <li
                            //  className="selected-page"
                            className={
                              index + 1 === searchData.page
                                ? "selected-page"
                                : null
                            }
                            onClick={(e) => {
                              setSearchData({
                                ...searchData,
                                page: index + 1,
                              });
                            }}
                          >
                            {index + 1}
                          </li>
                        );
                      }
                    })}
                </ul> */}
                {/* <ul>
                  {totalPageArray?.length &&
                    totalPageArray.map((_, index) => {
                      return (
                        <li
                          //  className="selected-page"
                          className={
                            index + 1 === searchData.page
                              ? "selected-page"
                              : null
                          }
                          onClick={(e) => {
                            setSearchData({
                              ...searchData,
                              page: index + 1,
                            });
                          }}
                        >
                          {index + 1}
                        </li>
                      );
                    })}
                </ul> */}
                {/* Pagination Component from MUI without arrows */}
                <Stack spacing={2}>
                  <Pagination
                    count={totalPage}
                    page={searchData.page}
                    siblingCount={0}
                    boundaryCount={2}
                    onChange={handlePageChange}
                    hidePrevButton
                    hideNextButton
                  />
                </Stack>
                {totalPageArray?.length !== searchData.page ? (
                  <button
                    className="right-arrow"
                    onClick={(e) =>
                      setSearchData({
                        ...searchData,
                        page: searchData.page + 1,
                      })
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#fff"
                      // className="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </button>
                ) : null}
              </div>

              <div className="right-tbl-bottom">
                <p>Rows Per Page</p>
                <select
                  className="no-opt-box"
                  name="limit"
                  onChange={(e) =>
                    setSearchData({
                      ...searchData,
                      page: 1,
                      limit: e.target.value,
                    })
                  }
                  value={searchData.limit}
                >
                  {totalOption &&
                    Array(totalOption)
                      .fill()
                      .map((_, option) => {
                        //  console.log("option-----------------------", option);
                        return (
                          <option value={(option + 1) * 5}>
                            {(option + 1) * 5}
                          </option>
                        );
                      })}
                </select>
              </div>
            </div>
          )}
        </div>
        <CancelMeetingModal
          setIsModalOpen={setIsCancelModalOpen}
          isModalOpen={isCancelModalOpen}
          handleSubmit={handleCancelMeeting}
        />
        <CommonModal
          message={"Do you want to close the meeting ?"}
          title={"Close Meeting"}
          setIsModalOpen={setIsCloseModalOpen}
          isModalOpen={isCloseModalOpen}
          handleSubmit={handleCloseMeeting}
          buttonName={"Close"}
        />
        <RescheduleMeetingModal
          setIsRescheduleModalOpen={setIsRescheduleModalOpen}
          isRescheduleModalOpen={isRescheduleModalOpen}
          meetingData={singleMeetingDetails}
          isFetchedMeetingList={meetingData.isFetchedMeetingList}
          // handleSubmit={handleRescheduleMeeting}
        />
      </div>
      <Footer />
    </>
  );
};

export default MeetingList;
