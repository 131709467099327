import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchAttendeesList,
  fetchUserMeetingList,
} from "../../redux/actions/meetingActions/MeetingAction";

// FILTER COMPONENT
const ActionFilterComponent = (props) => {
  console.log(props);
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const meetingData = useSelector((state) => state.meeting);
  const actionData = useSelector((state) => state.action);
  const [isDisabled, setIsDisabled] = useState(true);
  const localStorageData = JSON.parse(localStorage.getItem("userData"));
  const [selectedMeetingOption, setSelectedMeetingOption] = useState([
    { label: "Meeting Id", value: "" },
  ]);
  const [selectedOwnerOption, setSelectedOwnerOption] = useState([
    { label: "Name", value: "" },
  ]);
  const [searchData, setSearchData] = useState({
    toDate: "",
    fromDate: "",
    meetingId: "",
    ownerId: "",
    actionStatus: "",
  });

  useEffect(() => {
    setSearchData({
      ...props.initData,
    });
    dispatch(fetchUserMeetingList(userData.organizationId, accessToken));
  }, []);

  const handleChange = (e) => {
    console.log("on change------------------->>>>>>", e.target);
    const { name, value } = e.target;
    setSearchData({
      ...searchData,
      [name]: value,
    });
  };
  console.log("on change--------filter----------->>>>>>", searchData);
  useEffect(()=>{
    console.log("innnnnnnnnnnnn333", searchData);
    if (
      searchData.fromDate || searchData.toDate ||
      searchData.ownerId ||
      searchData.meetingId ||
      searchData.actionStatus
    ) {
      console.log("innnnnnnnnnnnn");
      setIsDisabled(false);
    }
  },[searchData.fromDate,searchData.toDate, searchData.meetingId, searchData.actionStatus, searchData.ownerId])
  
  
  const handleSubmit = (e) => {
    e.preventDefault(e);

    props.setfilter(false);

    props.filterData(searchData);
    setSearchData({
      ...searchData,
    });
  };

  const handleReset = (e) => {
    e.preventDefault(e);

    setSearchData({
      toDate: "",
      fromDate: "",
      meetingId: "",
      ownerId: "",
      actionStatus: "",
    });

    props.filterData({
      toDate: "",
      fromDate: "",
      meetingId: "",
      ownerId: "",
      actionStatus: "",
      page:1
    });
    setSelectedOwnerOption([
      { label: "Name", value: "" },
    ]);
   setSelectedMeetingOption([
      { label: "Meeting Id", value: "" },
    ]);
  };

  if (meetingData?.userMeetingList?.length !== 0) {
    var meetingIdOptions = meetingData?.userMeetingList?.map((meeting) => {
      console.log(meeting);
      const returnData = {
        value: meeting?._id,
        label: meeting?.meetingId,
        name: "meetingId",
      };
      console.log(returnData);
      return returnData;
    });
  }

  if (meetingData?.ownerList?.length !== 0) {
    var ownerOptions = meetingData?.ownerList?.map((user) => {
      console.log(user);
      const returnData = {
        value: user?._id,
        label: user?.name,
        name: "ownerId",
      };
      console.log(returnData);
      return returnData;
    });
  }

  const handleMeetingIdChange = (e) => {
    // dispatch(updateIsCreateMeetingProcessed(false));
    //  setErrors({});
    console.log("on change------------------->>>>>>", e);
    const { name, value } = e;
    setSearchData({
      ...searchData,
      [name]: value,
    });
    setSelectedMeetingOption(e);
  };
  const handleOwnerIdChange = (e) => {
    // dispatch(updateIsCreateMeetingProcessed(false));
    // setErrors({});
    console.log("on change------------------->>>>>>", e);
    const { name, value } = e;
    setSearchData({
      ...searchData,
      [name]: value,
    });
    setSelectedOwnerOption(e);
  };

  console.log(meetingIdOptions);
  return (
    <div className={props.filter ? "filter show" : "filter"} id="form-container">
      
      <div className="filter-container">
        <h4 className="filterheading mb-0">Filter Actions</h4>
        <button
          type="button"
          onClick={(e) => props.setfilter(false)}
          style={{ border: "none", padding: 0, margin: 0 }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            fill="currentColor"
            className="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </button>
      </div>

      <form id="myForm" onSubmit={handleSubmit}>
        <label htmlFor="from">From Date</label>
        <div className="from-to">
          <input
            className="filter-date"
            type="date"
            id="from"
            onChange={handleChange}
            name="fromDate"
            value={searchData.fromDate}
          />
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#fff"
            className="bi bi-calendar3 calender"
            viewBox="0 0 16 16"
          >
            <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z" />
            <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
          </svg> */}
        </div>
        <br />
        <label htmlFor="to">To Date</label>
        <div className="from-to">
          <input
            className="filter-date"
            type="date"
            id=""
            onChange={handleChange}
            name="toDate"
            value={searchData.toDate}
          />

          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#fff"
            className="bi bi-calendar3 calender"
            viewBox="0 0 16 16"
          >
            <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z" />
            <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
          </svg> */}
        </div>
        <br />
        <label>Filter By Meeting ID</label>
        {/* <select
          className="fltr-opt"
          aria-placeholder="Select Meeting Id"
          onChange={handleChange}
          name="meetingId"
          value={searchData.meetingId}
        >
          <option value="">Select Meeting ID</option>
          {meetingData?.userMeetingList?.length > 0 &&
            meetingData?.userMeetingList.map((meeting) => {
              return <option value={meeting?._id}>{meeting?.meetingId}</option>;
            })}
        </select> */}
        {/* <Select
          className="fltr-opt"
          defaultValue={selectedOption}
          name="attendeeId"
          // onChange={setSelectedOption}
          options={meetingIdOptions}
          onChange={handleMeetingIdChange}
        /> */}
        <Select
          className="fltr-opt"
          placeholder="Select Meeting ID"
          // defaultValue={attendeeOptions}
          name="meetingId"
          // onChange={setSelectedOption}
          options={meetingIdOptions}
          onChange={handleMeetingIdChange}
          value={selectedMeetingOption}
        />
        <br />
        <label>Filter By Owner</label>
        {/* <select
          className="fltr-opt"
          aria-placeholder="Select Owner"
          onChange={handleChange}
          name="ownerId"
          value={searchData.ownerId}
        >
          <option value="">Select Owner</option>
          {meetingData?.ownerList?.length > 0 &&
            meetingData?.ownerList.map((attendee) => {
              return <option value={attendee?._id}>{attendee?.name}</option>;
            })}
        </select> */}
        <Select
          // onChange={setSelectedOption}

          className="fltr-opt"
          placeholder="Select Meeting ID"
          // defaultValue={attendeeOptions}
          name="ownerId"
          // onChange={setSelectedOption}
          options={ownerOptions}
          onChange={handleOwnerIdChange}
          value={selectedOwnerOption}
        />
        <br />
        <label>Filter By Status</label>
        <select
          className="fltr-opt"
          aria-placeholder="Select Status"
          onChange={handleChange}
          name="actionStatus"
          value={searchData.actionStatus}
        >
          <option value="">Select Status</option>
          {actionData.statusData &&
            actionData.statusData.map((status) => {
              return (
                <option value={status}>
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </option>
              );
            })}
        </select>
        <div className="mt-2 form-btm-btn">
          <button className="reset" type="reset" onClick={handleReset}>
            <p>Reset</p>
          </button>
        
          <button className="add-btn Mom-btn" disabled={isDisabled}>
            <p>Filter</p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ActionFilterComponent;
