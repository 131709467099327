import React, { useEffect, useState } from "react";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { logOut } from "../../../redux/actions/authActions/authAction";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Navigate, Link } from "react-router-dom";
import LoaderButton from "../../Common/LoaderButton";

// Function to generate options for dropdowns
const generateOptions = (max, step) =>
  Array.from({ length: max / step }, (_, i) => i * step + step);

const Configuration = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (authData.isInValidUser) {
    console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnn");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const organizationId = userData?.organizationId;
  const accessToken = localStorage.getItem("accessToken");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [config, setConfig] = useState({
    amendmentRequestTime: 1,
    acceptanceRejectionEndtime: 1,
  });
  const [savedConfig, setSavedConfig] = useState({});

  const hourOptions = generateOptions(24, 1); // Generate options from 1 to 24 hours

  useEffect(() => {
    document.title = "Configuration: MinutesVault";
    const fetchConfig = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/V1/configuration/viewConfiguration/${organizationId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: accessToken,
            },
          }
        );
        if (response.data && response.data.data) {
          setConfig(response.data.data);
          setSavedConfig(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };
    fetchConfig();
  }, [organizationId, accessToken]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConfig((prevConfig) => ({
      ...prevConfig,
      [name]: parseInt(value, 10),
    }));
  };

  const handleBlurValidation = (name, value) => {
    const minHourLimit = 1;
    const maxHourLimit = 24;
    let error = "";

    if (value < minHourLimit || value > maxHourLimit) {
      error = `${
        name === "amendmentRequestTime"
          ? "Amendment Request Time"
          : "Acceptance Rejection End Time"
      } must be a positive integer between ${minHourLimit} and ${maxHourLimit}.`;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSaveConfiguration = async () => {
    const minHourLimit = 1;
    const maxHourLimit = 24;

    const validateConfig = () => {
      const errors = {};
      let isValid = true;

      const validateTime = (time, fieldName, displayName) => {
        if (
          !Number.isInteger(time) ||
          time < minHourLimit ||
          time > maxHourLimit
        ) {
          errors[
            fieldName
          ] = `${displayName} must be a positive integer between ${minHourLimit} and ${maxHourLimit}.`;
          isValid = false;
        }
      };

      validateTime(
        config.amendmentRequestTime,
        "amendmentRequestTime",
        "Amendment Request Time"
      );
      validateTime(
        config.acceptanceRejectionEndtime,
        "acceptanceRejectionEndtime",
        "Acceptance Rejection End Time"
      );

      setErrors(errors);
      return isValid;
    };

    setIsLoading(true);

    if (!validateConfig()) {
      Object.values(errors).forEach((error) => {
        toast.error(error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
      setIsLoading(false);
      return;
    }

    try {
      const configData = {
        organizationId,
        amendmentRequestTime: config.amendmentRequestTime,
        acceptanceRejectionEndtime: config.acceptanceRejectionEndtime,
        isAlert: false,
      };

      console.log(configData);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V1/configuration/createConfiguration`,
        { ...configData, organizationId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
        }
      );

      if (response.data.success) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setSavedConfig(configData);
      } else {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error saving configuration:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="meeting-room-page">
          <div className="meeting-header-text">
            <h4>Configuration</h4>
          </div>

          <form className="details-form">
            <div className="alert-box amendment">
              <div className="d-flex align-items-center">
                <label className="mb-0 me-2">
                  Allow MOM amendment requests within
                  <select
                    name="amendmentRequestTime"
                    value={config.amendmentRequestTime}
                    onChange={handleChange}
                    onBlur={(e) =>
                      handleBlurValidation(e.target.name, e.target.value)
                    }
                    className="custom-width-input me-2"
                  >
                    {hourOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  hour(s) of meeting MOM creation.
                </label>
              </div>
              {errors.amendmentRequestTime && (
                <p className="error mt-2">{errors.amendmentRequestTime}</p>
              )}
            </div>

            <div className="alert-box amendment mt-3">
              <div className="d-flex align-items-center">
                <label className="mb-0 me-2">
                  Allow acceptance or rejection of meeting minutes within
                  <select
                    name="acceptanceRejectionEndtime"
                    value={config.acceptanceRejectionEndtime}
                    onChange={handleChange}
                    onBlur={(e) =>
                      handleBlurValidation(e.target.name, e.target.value)
                    }
                    className="custom-width-input"
                  >
                    {hourOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  hour(s) of meeting MOM creation.
                </label>
              </div>
              {errors.acceptanceRejectionEndtime && (
                <p className="error mt-2">
                  {errors.acceptanceRejectionEndtime}
                </p>
              )}
            </div>

            <div className="mt-3">
              {isLoading ? (
                <LoaderButton />
              ) : (
                <button
                  className="add-btn send-email Mom-btn"
                  type="button"
                  onClick={handleSaveConfiguration}
                  disabled={isLoading}
                >
                  <p>Save Configuration</p>
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Configuration;
