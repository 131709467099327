import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  // @ts-ignore
} from "reactstrap";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
// @ts-ignore
//import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Button from "react-bootstrap/Button";
// @ts-ignore
import Header from "../Common/Header/Header";
import Sidebar from "../Common/Sidebar/Sidebar";
import MeetingHeader from "../Common/Header/MeetingHeader";
import ActionDetails from "./ActionDetails";
import "../Action/style/ActionDetails.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { logOut } from "../../redux/actions/authActions/authAction";
import {
  fetchActionList,
  processReassign,
  processRequestForReassign,
} from "../../redux/actions/actionAction/ActionAction";
import Alert from "../Common/Alert";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import { customName } from "../../helpers/commonHelpers";
import ActionDropDown from "./ActionDropDown";
import ActionFilterComponent from "./ActionFilterComponent";
import RequestForReassignModal from "./RequestForReassignModal";
import ReassignModal from "./ReassignModal";
import { viewMeetingStatistics } from "../../redux/actions/meetingActions/MeetingAction";

const ActionList = () => {
  const localOffset = new Date().getTimezoneOffset();
  console.log(new Date());
  console.log(localOffset);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const meetingData = useSelector((state) => state.meeting);
  const loginUserData = useSelector((state) => state.user);
  const actionData = useSelector((state) => state.action);
  const employeeData = useSelector((state) => state.user);
  const [filter, setfilter] = useState(false);

  if (authData.isInValidUser) {
    console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnn");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const [isRequestForReassignModalOpen, setIsRequestForReassignModalOpen] =
    useState(false);
  const [searchData, setSearchData] = useState({
    searchKey: "",
    page: 1,
    limit: 5,
    order: -1,
    filterData: {},
  });

  const filterData = (data) => {
    console.log(data);
    setSearchData({
      ...searchData,
      filterData: data,
      page: 1,
    });
  };
  useEffect(() => {
    document.title = "Action List: MinutesVault";
    dispatch(viewMeetingStatistics(userData?.organizationId, accessToken));
    console.log("userData------------", userData);
    if (!accessToken) {
      navigate("/login");
    }
    if (!userData) {
      navigate("/login");
    } else {
      console.log("userData------------", userData);
      console.log("repeat------------------------", searchData);
      const payload = {
        page: searchData.page,
        order: searchData.order,
        limit: searchData.limit,
        organizationId: userData.organizationId,
        actionStatus:
          searchData.filterData?.actionStatus !== ""
            ? searchData.filterData?.actionStatus
            : undefined,
        toDate: searchData.filterData?.toDate,
        fromDate: searchData.filterData?.fromDate,
        createdById:
          searchData.filterData?.ownerId !== ""
            ? searchData.filterData?.ownerId
            : undefined,
        meetingId:
          searchData.filterData?.meetingId !== ""
            ? searchData.filterData?.meetingId
            : undefined,
        accessToken,
      };
      if (searchData.searchKey !== "") {
        payload["searchKey"] = searchData.searchKey;
        setSearchData({
          ...searchData,
          page: 1,
        });
        payload.page = 1;
      }

      // if (searchData.filterData.page === 1) {
      //   //  payload["searchKey"] = searchData.searchKey;
      //   setSearchData({
      //     ...searchData,
      //     page: 1,
      //   });
      //   payload.page = 1;
      // }
      if (!authData.isInValidUser) {
        console.log("payload in ----------34-------", payload);
        dispatch(fetchActionList(payload));
      }
    }
    if (actionData.isSuccess) {
      setIsRequestForReassignModalOpen(false);
      setIsReassignModalOpen(false);
    }
  }, [
    searchData.searchKey,
    searchData.order,
    searchData.page,
    searchData.limit,
    searchData.filterData,
    actionData.isSuccess,
    actionData.isFetchedActionList,
  ]);
  console.log(searchData);
  const handleChange = (e) => {
    // console.log("on change------------------->>>>>>", e.target);
    const { name, value } = e.target;
    setSearchData({
      ...searchData,
      [name]: value,
    });
  };

  //   const totalOption = meetingData.totalCount
  //   ? Math.round(meetingData.totalCount / 5 + 0.5)
  //   : 0;
  //   const checkTotalPage=meetingData.totalCount / searchData.limit===1? Math.round(meetingData.totalCount / searchData.limit):
  // Math.round(meetingData.totalCount / searchData.limit + 0.5)
  // const totalPage = meetingData.totalCount
  //   ? checkTotalPage
  //   : 0;
  //   console.log(Math.round(meetingData.totalCount / searchData.limit))

  //   console.log(Math.round(meetingData.totalCount / searchData.limit + 0.5))
  // const totalPageArray = Array(totalPage).fill();
  // console.log(totalPageArray);

  const totalOption =
    actionData?.totalCount && actionData?.totalCount % 5 === 0
      ? Math.round(actionData.totalCount / 5)
      : actionData?.totalCount && actionData?.totalCount % 5 !== 0
      ? Math.round(actionData.totalCount / 5 + 0.5)
      : 0;
  console.log(totalOption);
  const checkTotalPage =
    actionData.totalCount % searchData.limit === 0
      ? Math.round(actionData.totalCount / searchData.limit)
      : Math.round(actionData.totalCount / searchData.limit + 0.5);
  console.log(checkTotalPage);

  // const totalOption = actionData.totalCount
  //   ? Math.round(actionData.totalCount / 5 + 0.5)
  //   : 0;
  // console.log(totalOption);
  // const checkTotalPage =
  //   actionData.totalCount / searchData.limit === 1
  //     ? Math.round(actionData.totalCount / searchData.limit)
  //     : Math.round(actionData.totalCount / searchData.limit + 0.5);
  const totalPage = actionData.totalCount ? checkTotalPage : 0;
  console.log(Math.round(actionData.totalCount / searchData.limit));
  console.log(totalPage);
  console.log(checkTotalPage);
  // const totalPage = actionData.totalCount / searchData.limit===1? Math.round(actionData.totalCount / searchData.limit):
  // Math.round(meetingData.totalCount / searchData.limit + 0.5)
  const totalPageArray = Array(totalPage).fill();
  console.log(totalPageArray);
  // console.log(
  //   "totalOption-------------------->",
  //   meetingData.totalCount / searchData.limit + 0.5,
  //   totalOption
  // );
  //const fromDataCount= searchData.page===1?searchData.limit+meetingData.meetingList.length
  // const fromDataCount =
  //   actionData.actionList?.length === 0
  //     ? 0
  //     : (searchData.page - 1) * searchData.limit + 1;
  // const toDataCount =
  //   (searchData.page - 1) * searchData.limit + actionData.actionList?.length;
  // //searchData.limit+meetingData.meetingList.length
  // const totalCount = actionData.totalCount;
  // console.log(fromDataCount, toDataCount, totalCount);

  const fromDataCount =
    actionData?.actionList?.length === 0
      ? 0
      : (searchData.page - 1) * searchData.limit + 1;
  const toDataCount =
    (searchData.page - 1) * searchData.limit + actionData?.actionList?.length;
  //searchData.limit+meetingData.meetingList.length
  const totalCount = actionData.totalCount;
  console.log(fromDataCount, toDataCount, totalCount);

  const [filterAction, setFilterAction] = useState(false);

  const showFilterAction = () => {
    setFilterAction(true);
  };

  const [modal, setModal] = useState(false);
  const [reassignDetailModal, setReassignDetailsModal] = useState(false);
  const [remark, setRemark] = useState("");

  const setReAssignRequestModalAction = () => {
    setModal(!modal);
  };

  const hideFilterAction = () => {
    setFilterAction(false);
  };

  const [dropdownOpen, setDropdownOpen] = useState([false, false]);

  const toggleModal = () => setModal(!modal);
  const toggleDropdown = (index) => {
    setDropdownOpen((prevState) =>
      prevState.map((isOpen, i) => (i === index ? !isOpen : isOpen))
    );
  };

  const toggleModalT = () => setReassignDetailsModal(!reassignDetailModal);

  const [locationType, setLocationType] = useState("");
  const [addPersonType, setAddPersonType] = useState("email");

  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
  const [actionId, setActionId] = useState(null);

  const onLocationTypeChange = (event) => {
    setLocationType(event.target.value);
  };

  const [showAddNewPeople, setShowAddNewPeople] = useState(false);
  const onToggleAddNewPeople = (event) => {
    setShowAddNewPeople(!showAddNewPeople);
  };

  const handleRequestForReassign = (reason) => {
    console.log("ccccccccccc", actionId, reason);

    dispatch(
      processRequestForReassign(
        actionId,
        { requestDetails: reason },
        accessToken
      )
    );
    setActionId(null);
  };

  const handleReassign = (data) => {
    console.log("ccccccccccc", actionId, data);

    dispatch(
      processReassign(
        actionId,
        {
          organizationId: userData.organizationId,
          reAssignedId: data.attendeeId !== "" ? data.attendeeId : undefined,
          reAssignReason: data.reason,
          isNewUser: data.name !== "" && data.email !== "" ? true : false,
          name: data.name,
          email: data.email,
          reassignedUserName: data.reassignedUserName,
        },
        accessToken
      )
    );
    setActionId(null);
  };
  const handleRequestForReassignModal = (actionId) => {
    console.log("gggggggggggggg", actionId);
    setActionId(actionId);
    setIsRequestForReassignModalOpen(true);
  };

  const handleReassignModal = (actionId) => {
    console.log("gggggggggggggg", actionId);
    setActionId(actionId);
    setIsReassignModalOpen(true);
  };
  const handlePageChange = (event, value) => {
    console.log(value);
    setSearchData({
      ...searchData,
      page: value,
    });
  };
  console.log(actionData);
  return (
    <>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="meeting-page ">
          <div className="meeting-header-text">
            <div>
              <h4>Actions</h4>
            </div>
            <div>
              <button
                type="button"
                className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
                id="open-form-btn-meetinglist"
                onClick={(e) => setfilter(true)}
              >
                <p>Filter</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-funnel filter-svg"
                  viewBox="0 0 16 16"
                >
                  <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                </svg>
              </button>
            </div>
          </div>

          <div className="color-box-resp">
            <div className="row mt-3">
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="panel-db panel to-cyan-400">
                  <div className="flex justify-between">
                    <div className="ltr:mr-1 rtl:ml-1 text-md font-semibold">
                      Total Due Actions
                    </div>
                  </div>
                  <div className="flex items-center mt-3">
                    <div className="text-3xl font-bold">
                      {meetingData?.meetingStatisticsData &&
                      !meetingData.loading
                        ? meetingData?.meetingStatisticsData?.totalDueAction
                        : 0}
                    </div>
                  </div>
                  <div className="flex items-center font-semibold mt-3">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2 shrink-0"
                    >
                      <path
                        opacity="0.5"
                        d="M3.27489 15.2957C2.42496 14.1915 2 13.6394 2 12C2 10.3606 2.42496 9.80853 3.27489 8.70433C4.97196 6.49956 7.81811 4 12 4C16.1819 4 19.028 6.49956 20.7251 8.70433C21.575 9.80853 22 10.3606 22 12C22 13.6394 21.575 14.1915 20.7251 15.2957C19.028 17.5004 16.1819 20 12 20C7.81811 20 4.97196 17.5004 3.27489 15.2957Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                      <path
                        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                    </svg>
                    All
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="panel-db panel to-violet-400">
                  <div className="flex justify-between">
                    <div className="ltr:mr-1 rtl:ml-1 text-md font-semibold">
                      Total Due Actions
                    </div>
                  </div>
                  <div className="flex items-center mt-3">
                    <div className="text-3xl font-bold">
                      {" "}
                      {meetingData?.meetingStatisticsData &&
                      !meetingData.loading
                        ? meetingData?.meetingStatisticsData
                            ?.totalHighPriorityDueAction
                        : 0}{" "}
                      /{" "}
                      {meetingData?.meetingStatisticsData &&
                      !meetingData.loading
                        ? meetingData?.meetingStatisticsData?.totalDueAction
                        : 0}
                    </div>
                  </div>
                  <div className="flex items-center font-semibold mt-3">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2 shrink-0"
                    >
                      <path
                        opacity="0.5"
                        d="M3.27489 15.2957C2.42496 14.1915 2 13.6394 2 12C2 10.3606 2.42496 9.80853 3.27489 8.70433C4.97196 6.49956 7.81811 4 12 4C16.1819 4 19.028 6.49956 20.7251 8.70433C21.575 9.80853 22 10.3606 22 12C22 13.6394 21.575 14.1915 20.7251 15.2957C19.028 17.5004 16.1819 20 12 20C7.81811 20 4.97196 17.5004 3.27489 15.2957Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                      <path
                        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                    </svg>
                    High priority
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="panel-db panel to-blue-400">
                  <div className="flex justify-between">
                    <div className="ltr:mr-1 rtl:ml-1 text-md font-semibold">
                      Total Due Actions
                    </div>
                  </div>
                  <div className="flex items-center mt-3">
                    <div className="text-3xl font-bold">
                      {meetingData?.meetingStatisticsData &&
                      !meetingData.loading
                        ? meetingData?.meetingStatisticsData
                            ?.totalMediumPriorityDueAction
                        : 0}{" "}
                      /{" "}
                      {meetingData?.meetingStatisticsData &&
                      !meetingData.loading
                        ? meetingData?.meetingStatisticsData?.totalDueAction
                        : 0}
                    </div>
                  </div>
                  <div className="flex items-center font-semibold mt-3">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2 shrink-0"
                    >
                      <path
                        opacity="0.5"
                        d="M3.27489 15.2957C2.42496 14.1915 2 13.6394 2 12C2 10.3606 2.42496 9.80853 3.27489 8.70433C4.97196 6.49956 7.81811 4 12 4C16.1819 4 19.028 6.49956 20.7251 8.70433C21.575 9.80853 22 10.3606 22 12C22 13.6394 21.575 14.1915 20.7251 15.2957C19.028 17.5004 16.1819 20 12 20C7.81811 20 4.97196 17.5004 3.27489 15.2957Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                      <path
                        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                    </svg>
                    Normal priority
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="panel-db panel to-fuchsia-400">
                  <div className="flex justify-between">
                    <div className="ltr:mr-1 rtl:ml-1 text-md font-semibold">
                      Total Due Actions
                    </div>
                  </div>
                  <div className="flex items-center mt-3">
                    <div className="text-3xl font-bold">
                      {meetingData?.meetingStatisticsData &&
                      !meetingData.loading
                        ? meetingData?.meetingStatisticsData
                            ?.totalLowPriorityDueAction
                        : 0}{" "}
                      /{" "}
                      {meetingData?.meetingStatisticsData &&
                      !meetingData.loading
                        ? meetingData?.meetingStatisticsData?.totalDueAction
                        : 0}
                    </div>
                  </div>
                  <div className="flex items-center font-semibold mt-3">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2 shrink-0"
                    >
                      <path
                        opacity="0.5"
                        d="M3.27489 15.2957C2.42496 14.1915 2 13.6394 2 12C2 10.3606 2.42496 9.80853 3.27489 8.70433C4.97196 6.49956 7.81811 4 12 4C16.1819 4 19.028 6.49956 20.7251 8.70433C21.575 9.80853 22 10.3606 22 12C22 13.6394 21.575 14.1915 20.7251 15.2957C19.028 17.5004 16.1819 20 12 20C7.81811 20 4.97196 17.5004 3.27489 15.2957Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                      <path
                        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      ></path>
                    </svg>
                    Low priority
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ) : !meetingData?.meetingStatisticsData && !meetingData.loading ? (
            <div className="no-data-img">
              <Alert
                status={"info"}
                message={"No data available."}
                timeoutSeconds={0}
              />
              <NoDataFound dataType={"meetingActivities"} />
            </div>
          ) : (
            <div
              className="meeting-page "
              style={{ textAlign: "center", paddingTop: 20 }}
            >
              <Loader />
            </div>
          )} */}

          {/* {filter ? (
            <ActionFilterComponent
              setfilter={setfilter}
              filterData={filterData}
              initData={searchData.filterData}
            />
          ) : null} */}

          <ActionFilterComponent
            setfilter={setfilter}
            filterData={filterData}
            initData={searchData.filterData}
            filter={filter}
          />

          <div className="mt-2 table-box responsive-table">
            <div className="tbl-text-search">
              <div className="left-tbl-text">
                {totalCount > 0 ? (
                  <p>
                    Showing {fromDataCount} to {toDataCount} of {totalCount}{" "}
                    entries
                  </p>
                ) : null}
              </div>
              <div className="search-box">
                <input
                  type="search"
                  placeholder="Search By Action Description..."
                  onChange={handleChange}
                  name="searchKey"
                  value={searchData.searchKey}
                  autoComplete="off"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#4F2CC8"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </div>
            </div>

            {!actionData.loading &&
            actionData.actionList?.length !== 0 &&
            actionData.isSuccess ? (
              // <div className="table-responsive">
              <table className="mt-4 table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">Due Date</th>
                    <th scope="col">Priority</th>

                    <th scope="col">Action Description</th>
                    <th scope="col">Meeting Title & ID</th>
                    {employeeData?.userData?.isMeetingOrganiser ||
                    employeeData?.userData?.isAdmin ? (
                      <th scope="col" className="text-align-center">
                        Assigned to
                      </th>
                    ) : null}

                    {/* <th scope="col">Assigned to</th> */}

                    <th scope="col">Status</th>
                    <th className="action-col" scope="col">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {actionData.actionList?.map((action) => {
                    return (
                      <tr>
                        <td
                          data-label="Due Date"
                          className="meeting-td-width-6"
                        >
                          {action?.dueDate}
                        </td>
                        <td
                          data-label="Priority"
                          className="meeting-td-width-7"
                        >
                          {" "}
                          {action?.priority?.charAt(0).toUpperCase() +
                            action?.priority?.slice(1).toLowerCase()}
                        </td>
                        <td
                          data-label="Action Description"
                          className="meeting-td-width word-break"
                        >
                          {" "}
                          {action?.description?.charAt(0).toUpperCase() +
                            action?.description?.slice(1).toLowerCase()}
                        </td>
                        <td
                          data-label="Meeting Title & ID"
                          className="meeting-td-width word-break"
                        >
                          {" "}
                          <p>
                            {" "}
                            {action?.meetingDetails?.length !== 0
                              ? action?.meetingDetails[0]?.title
                              : "Not Available"}{" "}
                            {/* (
                            {action?.meetingDetails?.length !== 0
                              ? action?.meetingDetails[0]?.meetingId
                              : "Not Available"}
                            ) */}
                          </p>
                          <p className="detail-date-time">
                            Meeting ID - {action?.meetingDetails[0]?.meetingId}
                            {/* {formatDateTimeFormat(meeting.date).formattedTime} */}
                          </p>
                        </td>
                        {employeeData?.userData?.isMeetingOrganiser ||
                        employeeData?.userData?.isAdmin ? (
                          <td
                            data-label="Assigned to"
                            className="meeting-td-width-6  attendees-center"
                          >
                            {action?.assignedUserDetails &&
                            action?.assignedUserDetails?.length !== 0 ? (
                              <div className="attendees">
                                <div className="attendee-list">
                                  {customName(
                                    action?.assignedUserDetails[0]?.name
                                  )}
                                </div>
                              </div>
                            ) : (
                              "Not Available"
                            )}
                          </td>
                        ) : null}
                        {/* 
                        {action?.assignedUserId?.toString() ===
                          employeeData?.userData?._id?.toString() &&
                        (!employeeData?.userData?.isMeetingOrganiser ||
                          !employeeData?.userData?.isAdmin) ? null : (
                          <td data-label="Asigned User">
                            <div className="attendees">
                              <div className="attendee-list">
                                {customName(
                                  action?.assignedUserDetails?.length !== 0
                                    ? action?.assignedUserDetails[0]?.name
                                    : "Not Available"
                                )}
                              </div>
                            </div>
                          </td>
                        )} */}

                        <td data-label="Status" className="meeting-td-width-1">
                          <span
                            className={
                              // action.isComplete
                              //   ? "badge bg-success bg-opacity-10 "
                              //   : action?.reassigneRequestDetails?.find(
                              //       (details) =>
                              //         details.userId.toString() ===
                              //         userData._id.toString()
                              //     )
                              //   ? "badge bg-warning bg-opacity-10 "
                              //   : "badge bg-primary bg-opacity-10 "

                              action?.isComplete
                                ? "badge bg-info bg-opacity-10 "
                                : action?.isReopened
                                ? "badge bg-danger bg-opacity-10 "
                                : action?.isApproved
                                ? "badge bg-success bg-opacity-10 "
                                : //  action?.reassigneRequestDetails?.find(
                                //     (details) =>
                                //       details?.userId?.toString() ===
                                //       userData?._id?.toString()
                                //   )?.isRejected === false
                                // (employeeData.userData.isAdmin ||
                                //     employeeData.userData.isMeetingOrganiser) &&
                                //   action?.reassigneRequestDetails.length !==
                                //     0 &&
                                //   action?.reassigneRequestDetails[
                                //     action?.reassigneRequestDetails.length - 1
                                //   ].isAccepted === false &&
                                //   action?.reassigneRequestDetails[
                                //     action?.reassigneRequestDetails.length - 1
                                //   ].isRejected === false
                                employeeData?.userData?.isAdmin === false &&
                                  employeeData?.userData?.isMeetingOrganiser ===
                                    false &&
                                  action?.isRequested === true &&
                                  action?.reassigneRequestDetails[
                                    action?.reassigneRequestDetails?.length - 1
                                  ]?.userId?.toString() ===
                                    employeeData?.userData?._id?.toString()
                                ? "badge bg-warning bg-opacity-10 "
                                : (employeeData.userData.isAdmin ||
                                    employeeData.userData.isMeetingOrganiser) &&
                                  action?.isRequested === true
                                ? // : employeeData?.userData?.isAdmin === false &&
                                  //   employeeData?.userData?.isMeetingOrganiser ===
                                  //     false &&
                                  //   action?.reassigneRequestDetails.length !==
                                  //     0 &&
                                  //   action?.reassigneRequestDetails[
                                  //     action?.reassigneRequestDetails.length - 1
                                  //   ].isAccepted === false &&
                                  //   action?.reassigneRequestDetails[
                                  //     action?.reassigneRequestDetails.length - 1
                                  //   ].isRejected === false &&
                                  //   action?.reassigneRequestDetails[
                                  //     action?.reassigneRequestDetails?.length - 1
                                  //   ]?.userId?.toString() ===
                                  //     employeeData?.userData?._id?.toString()
                                  "badge bg-warning bg-opacity-10 "
                                : (employeeData.userData.isAdmin ||
                                    employeeData.userData.isMeetingOrganiser) &&
                                  action?.isPending === true
                                ? "badge bg-danger bg-opacity-10 "
                                : "badge bg-danger bg-opacity-10 "
                            }
                          >
                            {action?.isComplete
                              ? "Completed"
                              : action?.isReopened
                              ? "Reopened"
                              : action?.isApproved
                              ? "Approved"
                              : employeeData?.userData?.isAdmin === false &&
                                employeeData?.userData?.isMeetingOrganiser ===
                                  false &&
                                action?.isRequested === true &&
                                action?.reassigneRequestDetails[
                                  action?.reassigneRequestDetails?.length - 1
                                ]?.userId?.toString() ===
                                  employeeData?.userData?._id?.toString()
                              ? //  &&
                                // action?.reassigneRequestDetails?.find(
                                //     (details) =>
                                //       details?.userId?.toString() ===
                                //       userData?._id?.toString()
                                //   )?.isRejected === false
                                "Reassignment Requested"
                              : employeeData?.userData?.isAdmin === false &&
                                employeeData?.userData?.isMeetingOrganiser ===
                                  false &&
                                action?.isPending === true
                              ? //  &&
                                // action?.reassigneRequestDetails[
                                //   action?.reassigneRequestDetails?.length - 1
                                // ]?.userId?.toString() ===
                                //   employeeData?.userData?._id?.toString()
                                //  &&
                                // action?.reassigneRequestDetails?.find(
                                //     (details) =>
                                //       details?.userId?.toString() ===
                                //       userData?._id?.toString()
                                //   )?.isRejected === false
                                "Pending"
                              : (employeeData.userData.isAdmin ||
                                  employeeData.userData.isMeetingOrganiser) &&
                                action?.isPending === true
                              ? "Pending"
                              : (employeeData.userData.isAdmin ||
                                  employeeData.userData.isMeetingOrganiser) &&
                                action?.reassigneRequestDetails.length === 0
                              ? "Pending"
                              : //   : (employeeData.userData.isAdmin ||
                              //     employeeData.userData.isMeetingOrganiser) &&
                              //   action?.reassigneRequestDetails.length !== 0 &&
                              //   action?.reassigneRequestDetails[
                              //     action?.reassigneRequestDetails.length - 1
                              //   ].isAccepted === false &&
                              //   action?.reassigneRequestDetails[
                              //     action?.reassigneRequestDetails.length - 1
                              //   ].isRejected === false
                              // ? "Pending4"
                              (employeeData.userData.isAdmin ||
                                  employeeData.userData.isMeetingOrganiser) &&
                                action?.isRequested === true
                              ? // action?.reassigneRequestDetails.length !== 0 &&
                                // action?.reassigneRequestDetails[
                                //   action?.reassigneRequestDetails.length - 1
                                // ].isAccepted === false &&
                                // action?.reassigneRequestDetails[
                                //   action?.reassigneRequestDetails.length - 1
                                // ].isRejected === false
                                "Reassignment Requested"
                              : null}
                          </span>

                          {/* <Button variant="success"  size="sm" style={{fontWeight:"bold"}} >Scheduled</Button> */}
                        </td>
                        {/* <td data-label="Status"><span className="badge bg-success bg-opacity-10 text-success">Completed</span></td> */}
                        <td
                          data-label="Action"
                          className="text-align-center meeting-td-width-7"
                        >
                          <div className="d-inline-block menu-dropdown custom-dropdown">
                            <ActionDropDown
                              action={action}
                              //  status={meeting.meetingStatus.status}
                              handleRequestForReassignModal={() => {
                                handleRequestForReassignModal(action?._id);
                              }}
                              handleReassignModal={() => {
                                handleReassignModal(action?._id);
                              }}
                            />

                            {/* {buttonStatus ? <VariantsExample /> : null} */}

                            {/* <div
                            aria-labelledby="dropdownBasic1"
                            className="list-dropdown"
                          >
                            <div className="dropdown-divider"></div>
                          </div> */}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  <tr></tr>
                </tbody>
              </table>
            ) : // </div>
            !actionData.loading && actionData.actionList?.length === 0 ? (
              <div className="no-data-img">
                <Alert
                  status={"info"}
                  message={"No data available."}
                  timeoutSeconds={0}
                />
                {/* <Alert message="No Data Found !" status={false} /> */}

                <NoDataFound dataType={"action"} />
                <Button
                  className="mt-2"
                  variant="primary"
                  onClick={(e) => {
                    setSearchData({
                      ...searchData,
                      searchKey: "",
                      filterData: {},
                      page: 1,
                    });
                  }}
                >
                  Clear
                </Button>
              </div>
            ) : (
              <div
                className="meeting-page "
                style={{ textAlign: "center", paddingTop: 20 }}
              >
                <Loader />
              </div>
            )}

            {totalCount <= 1 ? null : (
              <div className="tbl-bottom">
                <div className="left-tbl-bottom">
                  {searchData.page !== 1 ? (
                    <button
                      className="left-arrow"
                      onClick={(e) =>
                        setSearchData({
                          ...searchData,
                          page: searchData.page - 1,
                        })
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#fff"
                        className="bi bi-chevron-left"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                        />
                      </svg>
                    </button>
                  ) : null}
                  {/* <ul>
                    {totalPageArray?.length &&
                      totalPageArray.map((_, option) => {
                        //   console.log("option-----------------------", option);
                        return (
                          <li
                            //  className="selected-page"
                            className={
                              option + 1 === searchData.page
                                ? "selected-page"
                                : null
                            }
                            onClick={(e) => {
                              setSearchData({
                                ...searchData,
                                page: option + 1,
                              });
                            }}
                          >
                            {option + 1}
                          </li>
                        );
                      })}
                  </ul> */}
                  {/* Pagination Component from MUI without arrows */}
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPage}
                      page={searchData.page}
                      siblingCount={0}
                      boundaryCount={2}
                      onChange={handlePageChange}
                      hidePrevButton
                      hideNextButton
                    />
                  </Stack>
                  {totalPageArray?.length !== searchData.page ? (
                    <button
                      className="right-arrow"
                      onClick={(e) =>
                        setSearchData({
                          ...searchData,
                          page: searchData.page + 1,
                        })
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#fff"
                        // className="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    </button>
                  ) : null}
                </div>

                <div className="right-tbl-bottom">
                  <p>Rows Per Page</p>
                  <select
                    className="no-opt-box"
                    name="limit"
                    onChange={(e) =>
                      setSearchData({
                        ...searchData,
                        page: 1,
                        limit: e.target.value,
                      })
                    }
                    value={searchData.limit}
                  >
                    {totalOption &&
                      Array(totalOption)
                        .fill()
                        .map((_, option) => {
                          //  console.log("option-----------------------", option);
                          return (
                            <option value={(option + 1) * 5}>
                              {(option + 1) * 5}
                            </option>
                          );
                        })}
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <RequestForReassignModal
        setIsModalOpen={setIsRequestForReassignModalOpen}
        isModalOpen={isRequestForReassignModalOpen}
        handleSubmit={handleRequestForReassign}
      />
      <ReassignModal
        setIsReassignModalOpen={setIsReassignModalOpen}
        isModalOpen={isReassignModalOpen}
        handleSubmit={handleReassign}
      />
    </>
  );
};
export default ActionList;
