import React, { useState } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../../node_modules/axios/index";
import { ToastContainer, toast } from "react-toastify";
import { updateSuccess } from "../../redux/actions/notificationAction/NotificationAction";

const NotificationDropdown = (props) => {
  console.log("props---------------status", props);
  const employeeData = useSelector((state) => state.user);
  console.log(employeeData.userData);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData._id;
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
  const [selectedNotification, setSelectedNotification] = useState(null);

  const updateNotifaction = async (payload) => {
    try {
      console.log(props);
      dispatch(updateSuccess(false));
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/V1/notification/editNotification/${props.notificationId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
        }
      );
      // alert("Marked as read");
      if (response.data.success) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
        dispatch(updateSuccess(true));
      } else {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const markAsRead = async () => {
  //   try {
  //     await axios.put(
  //       `${process.env.REACT_APP_API_URL}/api/V1/department/editDepartment/${props.actionId}`,
  //       { isRead: true },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: accessToken,
  //         },
  //       }
  //     );
  //     alert("Marked as read");
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const markAsUnread = async () => {
  //   try {
  //     await axios.put(
  //       `${process.env.REACT_APP_API_URL}/api/V1/department/editDepartment/${props.actionId}`,
  //       { isRead: false },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: accessToken,
  //         },
  //       }
  //     );
  //     alert("Marked as read");
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const markAsImportant = async () => {
  //   try {
  //     const response = await axios.put(
  //       `${process.env.REACT_APP_API_URL}/api/V1/department/editDepartment/${props.actionId}`,
  //       { isImportant: true },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: accessToken,
  //         },
  //       }
  //     );
  //   } catch (error) {}
  // };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#000"
            className="bi bi-three-dots-vertical"
            viewBox="0 0 16 16"
          >
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
          </svg>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {/* <Dropdown.Item href="#/action-2"> */}
          {props?.notification?.isRead === false ? (
            <Dropdown.Item onClick={() => updateNotifaction({ isRead: true })}>
              <Link
                // state={{ meetingId: props.meetingId,isViewMeetingPage:true}}
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-eye me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                </svg>
                Mark As Read
              </Link>
            </Dropdown.Item>
          ) : null}
          {props?.notification?.isRead === true ? (
            <Dropdown.Item onClick={() => updateNotifaction({ isRead: false })}>
              <Link
                // state={{ meetingId: props.meetingId,isViewMeetingPage:true}}
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-eye-slash me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                  <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                  <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                </svg>
                Mark As UnRead
              </Link>
            </Dropdown.Item>
          ) : null}
          {props?.notification?.isImportant === false ? (
            <Dropdown.Item
              onClick={() => updateNotifaction({ isImportant: true })}
            >
              <Link
                // state={{ meetingId: props.meetingId,isViewMeetingPage:true}}
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-star me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                </svg>
                Mark As Important
              </Link>
            </Dropdown.Item>
          ) : null}
          {props?.notification?.isImportant === true ? (
            <Dropdown.Item
              onClick={() => updateNotifaction({ isImportant: false })}
            >
              <Link
                // state={{ meetingId: props.meetingId,isViewMeetingPage:true}}
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-star me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                </svg>
                Mark As Not Important
              </Link>
            </Dropdown.Item>
          ) : null}
          <Dropdown.Item onClick={() => updateNotifaction({ isDelete: true })}>
            <Link
              // state={{ meetingId: props.meetingId,isViewMeetingPage:true}}
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-trash me-2"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
              </svg>
              Delete
            </Link>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );  
};

export default NotificationDropdown;
