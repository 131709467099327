import React, { useState, useEffect } from "react";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import MeetingHeader from "../../Common/Header/MeetingHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../../node_modules/axios/index";
import { Modal, Button, Table, Dropdown, Form } from "react-bootstrap";
import Loader from "../../Common/Loader";
import Alert from "../../Common/Alert";
import NoDataFound from "../../Common/NoDataFound";
import LoaderButton from "../../Common/LoaderButton";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { logOut } from "../../../redux/actions/authActions/authAction";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import constatntMessages from "../../../constants/constatntMessages";

const MeetingRoom = () => {
  //Create
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?._id;
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (authData.isInValidUser) {
    console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnn");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  console.log("userId====", userId);
  const organizationId = userData?.organizationId;
  const accessToken = localStorage.getItem("accessToken");
  const [roomData, setRoomData] = useState({ title: "", location: "" });
  const [formValues, setFormValues] = useState({ title: "", location: "" });
  const [errors, setErrors] = useState({});

  //List
  const [roomslist, setRoomsList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState(-1);
  const [isFetching, setIsFetching] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isGetApiRes, setIsGetApiRes] = useState(false);
  const [apiResData, setApiResData] = useState({
    isSuccess: false,
    message: "",
  });

  //Edit
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [titleName, setTitleName] = useState("");
  const [roomLocation, setRoomLocation] = useState("");
  const [editformValues, setEditFormValues] = useState({
    title: "",
    location: "",
  });

  //Delete
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [roomToDelete, setRoomToDelete] = useState(null);
  console.log("unitToDelete-->", roomToDelete);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setRoomData((prevRoomData) => ({
      ...prevRoomData,
      [name]: value,
    }));

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const isValidate = () => {
    let isValid = true;
    const errors = {};
    const excludeHtml = /^[0-9a-zA-Z ,&,/-]+$/;

    if (!formValues.title.trim()) {
      errors.title = "Title is required";
      isValid = false;
    } else if (!excludeHtml.test(formValues.title)) {
      errors.title = constatntMessages.invalidInput;
      isValid = false;
    } else if (
      formValues.title.trim().length < 2 ||
      formValues.title.trim().length > 100
    ) {
      errors.title = constatntMessages.characterSizeTitle;
      isValid = false;
    }

    if (!formValues.location.trim()) {
      errors.location = "Location is required";
      isValid = false;
    } else if (!excludeHtml.test(formValues.location)) {
      errors.location = constatntMessages.invalidInput;
      isValid = false;
    } else if (
      formValues.location.trim().length < 2 ||
      formValues.location.trim().length > 100
    ) {
      errors.location = constatntMessages.characterSizeLocation;
      isValid = false;
    }

    setErrors(errors);

    return isValid;
  };

  const roomTitleValidationCheck = () => {
    let isValid = true;
    const newErrors = { ...errors };
    const excludeHtml = /^[0-9a-zA-Z ,&,/-]+$/;

    if (!formValues.title.trim()) {
      isValid = false;
      newErrors.title = "Room Title is required";
    } else if (!excludeHtml.test(formValues.title)) {
      isValid = false;
      newErrors.title = constatntMessages.invalidInput;
    } else if (formValues.title.length < 2 || formValues.title.length > 100) {
      isValid = false;
      newErrors.title = "Title must be between 2 - 100  characters";
    }

    setErrors(newErrors);
    return newErrors;
  };

  const roomLocationValidationCheck = () => {
    const newErrors = { ...errors };
    let isValid = true;
    const excludeHtml = /^[0-9a-zA-Z ,&,/-]+$/;
    if (!formValues.location.trim()) {
      isValid = false;
      newErrors.location = "Room Location is required";
    } else if (!excludeHtml.test(formValues.location)) {
      isValid = false;
      newErrors.location = constatntMessages.invalidInput;
    } else if (
      formValues.location.length < 2 ||
      formValues.location.length > 100
    ) {
      isValid = false;
      newErrors.location = constatntMessages.characterSizeLocation;
    }

    setErrors(newErrors);
    return newErrors;
  };

  //Edit
  const isEditValidate = () => {
    const errors = {};
    const excludeHtml = /^[0-9a-zA-Z ,&,/-]+$/;
    if (!titleName.trim()) {
      errors.titleName = "Title is required";
    } else if (!excludeHtml.test(titleName)) {
      errors.titleName = constatntMessages.invalidInput;
    } else if (titleName.trim().length < 2 || titleName.trim().length > 100) {
      errors.titleName = "Title must be between 2-100  characters";
    } else {
      delete errors.titleName;
    }

    if (!roomLocation.trim()) {
      errors.roomLocation = "Location is required";
    } else if (!excludeHtml.test(roomLocation)) {
      errors.roomLocation = constatntMessages.invalidInput;
    } else if (
      roomLocation.trim().length < 2 ||
      roomLocation.trim().length > 100
    ) {
      errors.roomLocation = "Location must be between 2-100 characters";
    } else {
      delete errors.roomLocation;
    }

    setErrors(errors);
    return errors;
  };

  const editRoomNameValidationCheck = () => {
    const newErrors = { ...errors };
    const excludeHtml = /^[0-9a-zA-Z ,&,/-]+$/;

    if (!titleName.trim()) {
      newErrors.titleName = "Title is required";
    } else if (!excludeHtml.test(titleName)) {
      newErrors.titleName = constatntMessages.invalidInput;
    } else {
      delete newErrors.titleName;
    }

    setErrors(newErrors);
    return newErrors;
  };

  const editRoomAddressValidationCheck = () => {
    const errors = {};
    const excludeHtml = /^[0-9a-zA-Z ,&,/-]+$/;

    if (!roomLocation.trim()) {
      errors.roomLocation = "Location is required";
    } else if (!excludeHtml.test(roomLocation)) {
      errors.roomLocation = constatntMessages.invalidInput;
    } else {
      delete errors.roomLocation; // Remove the error if validation passes
    }

    setErrors(errors); // Update the state with the new errors
    return errors;
  };

  //Create Meeting Room
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsGetApiRes(false);
    setIsLoading(true);

    const trimmedRoomData = {
      ...roomData,
      title: roomData.title.trim(),
      location: roomData.location.trim(),
    };

    console.log("Check validation", isValidate());

    if (isValidate()) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/V1/room/createRoom`,
          { ...trimmedRoomData, organizationId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: accessToken,
            },
          }
        );

        if (response.data.success) {
          setFormValues({ title: "", location: "" });
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          fetchRoomData();
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

        setApiResData({
          isSuccess: response.data.success,
          message: response.data.message,
        });
        setIsGetApiRes(true);
      } catch (error) {
        console.log("error--->", error);
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      return;
    }
  };

  //List Meeting Room
  const fetchMeetingRoom = async (bodyData) => {
    try {
      console.log("bodyData-->", bodyData);
      setIsFetching(true);
      const headerObject = {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
        params: { limit, page, order },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V1/room/viewRooms`,
        bodyData,
        headerObject
      );
      const data = response.data.data || {};
      console.log("data room----", data.roomsDatas);
      setRoomsList(data.roomsDatas || []);
      setTotalCount(data.totalCount || 0);
      setIsFetching(false);
    } catch (error) {
      console.log("Error while Fetching Room:", error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    document.title = "Meeting Room: MinutesVault";
    fetchRoomData();
  }, [searchKey, page, limit, order]);

  const handleSearch = (event) => {
    setSearchKey(event.target.value);
    setPage(1);
  };

  const fetchRoomData = async () => {
    const bodyData = searchKey
      ? { searchKey, organizationId }
      : { organizationId };
    console.log("bodyData-->", bodyData);
    await fetchMeetingRoom(bodyData);
  };
  const handleClearSearch = () => {
    setSearchKey("");
    setPage(1);
    fetchRoomData();
  };

  // const formatDateTimeFormat = (date) => {
  //   const sourceDate = new Date(date).toDateString();
  //   const sourceTime = new Date(date).toLocaleTimeString();
  //   const [, month, day, year] = sourceDate.split(" ");
  //   const formattedDate = [day, month, year].join(" ");
  //   const [hour, minute] = sourceTime.split(":");
  //   const formattedTime = `${hour}:${minute} ${sourceTime.split(" ")[1]}`;

  //   console.log("formattedTime-->", formattedTime);
  //   return { formattedTime, formattedDate };
  // };

  const formatDateTimeFormat = (date) => {
    const dateInDFormat = new Date(date);
    // Get day, month, and year
    let day = dateInDFormat.getUTCDate();
    const month = dateInDFormat.toLocaleString("en-US", { month: "short" });
    const year = dateInDFormat.getUTCFullYear();
    let dayFormatted = day < 10 ? "0" + day : day;
    // Get hours and minutes in 12-hour format
    let hours = dateInDFormat.getUTCHours();
    const minutes = dateInDFormat.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    // Format minutes
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedDate = `${dayFormatted} ${month} ${year}`;
    const formattedTime = `${hours}:${formattedMinutes} ${ampm}`;
    return { formattedTime, formattedDate };
  };

  const fromDataCount = roomslist.length === 0 ? 0 : (page - 1) * limit + 1;
  const toDataCount = (page - 1) * limit + roomslist.length;
  const totalOption = Math.round((totalCount % 5) + 0.5);
  const totalPage = Math.ceil(totalCount / limit);
  const totalPageArray = Array.from({ length: totalPage }, (_, i) => i + 1);
  const maxOption = Math.ceil(totalCount / 5) * 5;

  const handleRowsPerPageChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
    setPage(1);
  };

  //Edit room
  const handleEditClick = (rooms) => {
    setSelectedRoom(rooms);
    console.log("room---", rooms);
    setTitleName(rooms.title);
    setRoomLocation(rooms.location);
    setShowEditModal(true);
  };

  const handleEditSave = async (e) => {
    const newErrors = isEditValidate();
    setIsEditLoading(true);
    if (Object.keys(newErrors).length === 0) {
      try {
        // setErrors({});
        // if (!/^[0-9a-fA-F]{24}$/.test(organizationId)) {
        //   setErrors({ editOrganizationId: "Invalid organization ID format" });
        //   return;
        // }

        const updatedRoom = {
          title: titleName.trim(),
          location: roomLocation,
          organizationId,
        };

        console.log("updatedRoom--->", updatedRoom);
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/V1/room/editRoom/${selectedRoom._id}`,
          updatedRoom,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: accessToken,
            },
          }
        );
        if (response.data.success) {
          setEditFormValues({ title: "", location: "" });
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setSelectedRoom(null);
          setShowEditModal(false);
          fetchRoomData();
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
          setShowEditModal(true);
        }
        // setShowEditModal(false);
        setErrors({ ...errors, [editformValues.title]: "" });
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
        console.log("Error while updating Room:", error);
        setIsEditLoading(false);
      } finally {
        setIsEditLoading(false);
      }
    } else {
      setIsEditLoading(false);
    }
  };

  //Delete
  const handleDeleteClick = (rooms) => {
    setRoomToDelete(rooms);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (roomToDelete) {
        await deleteRoom(roomToDelete._id);
        setShowDeleteModal(false);
        setRoomToDelete(null);
        fetchRoomData();
      }
    } catch (error) {
      console.error("Error while deleting Room:", error);
    }
  };

  const deleteRoom = async (roomId) => {
    try {
      console.log("roomId-->", roomId);
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/V1/room/deleteRoom/${roomId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
        }
      );
      // fetchRoomData();
      if (response.data.success) {
        console.log("In");
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
        const totalRemainingItems = totalCount - 1;
        const maxPages = Math.ceil(totalRemainingItems / limit);
        if (page > maxPages) {
          setPage(page - 1);
        }
        fetchRoomData();
      }
      return response.data;
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        // transition: Bounce,
      });
      console.error("Error deleting unit:", error);
      throw error;
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="Action-list-page input-width">
          <div className="meeting-header-text">
            <h4>Add Meeting Room</h4>
          </div>

          <div className="mt-2 table-box">
            <form className="meeting-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="mb-1">
                      Room Title<span className="star-mark"> *</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Room Title"
                      autoComplete="off"
                      name="title"
                      onBlur={roomTitleValidationCheck}
                      onChange={handleChange}
                      value={formValues.title}
                    />
                    {errors.title && (
                      <span className="error-message">{errors.title}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="mb-1">
                      Meeting Room Location<span className="star-mark"> *</span>
                    </label>
                    <textarea
                      name="location"
                      placeholder="Enter Location"
                      autoComplete="off"
                      id=""
                      cols="10"
                      rows="3"
                      value={formValues.location}
                      onChange={handleChange}
                      onBlur={roomLocationValidationCheck}
                    ></textarea>
                    {errors.location && (
                      <span className="error-message">{errors.location}</span>
                    )}
                  </div>
                </div>
              </div>

              {isLoading ? (
                <LoaderButton />
              ) : (
                <button className="save Mom-btn" disabled={isLoading}>
                  <p>Submit</p>
                </button>
              )}
            </form>
          </div>

          <div className="meeting-header-text mt-4">
            <h4 className="mt-2">Manage Meeting Rooms</h4>
          </div>
          <></>
          <div className="mt-2 table-box responsive-table">
            <div className="tbl-text-search">
              <div className="left-tbl-text">
                {totalCount > 0 ? (
                  <p>
                    Showing {fromDataCount} to {toDataCount} of {totalCount}{" "}
                    entries
                  </p>
                ) : null}
              </div>
              <div className="search-box">
                <input
                  type="search"
                  placeholder="Search By Room Title"
                  autoComplete="off"
                  value={searchKey}
                  onChange={handleSearch}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#4F2CC8"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </div>
            </div>

            {isFetching ? (
              <div className="tbl-text-search">
                <div className="meeting-page loader-cont">
                  <Loader />
                </div>
              </div>
            ) : roomslist.length > 0 ? (
              <>
                <div className="table-responsive">
                  <Table className="mt-4 table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Sl. No.</th>
                        <th>Room Title </th>
                        <th>Room Location </th>
                        <th>Updated At</th>
                        <th className="action-col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roomslist.map((rooms, index) => {
                        const serialNumber = index + 1 + (page - 1) * limit;

                        return (
                          <tr key={index}>
                            <td
                              data-label="Sl. No."
                              className="meeting-td-width-7"
                            >
                              {serialNumber}
                            </td>
                            <td
                              data-label="Room Title"
                              className=" meeting-td-width-4 word-break"
                            >
                              {rooms.title}
                            </td>
                            <td
                              data-label="Room Location"
                              className="word-break"
                            >
                              {rooms.location}
                            </td>
                            <td
                              data-label="Updated At"
                              className="meeting-td-width-6"
                            >
                              {rooms.formattedDate}
                              <p className="detail-date-time">
                                {rooms.formattedTime}
                              </p>
                            </td>
                            <td
                              data-label="Action"
                              className="text-align-center meeting-td-width-7"
                            >
                              <Dropdown>
                                <div className="dropdown-opt d-inline-block menu-dropdown custom-dropdown">
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="#000"
                                      className="bi bi-three-dots-vertical"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                    </svg>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => handleEditClick(rooms)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="me-2 bi bi-pencil-square"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path
                                          fillRule="evenodd"
                                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                        />
                                      </svg>
                                      Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => handleDeleteClick(rooms)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="me-2 bi bi-trash3"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                      </svg>
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </div>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
                <div className="tbl-bottom">
                  <div className="left-tbl-bottom">
                    {/* Left Arrow Button */}
                    {page !== 1 ? (
                      <button
                        className="left-arrow"
                        onClick={() => setPage(page > 1 ? page - 1 : 1)}
                        disabled={page === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#fff"
                          className="bi bi-chevron-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                          />
                        </svg>
                      </button>
                    ) : null}

                    {/* Pagination Component from MUI without arrows */}
                    <Stack spacing={2}>
                      <Pagination
                        count={totalPage}
                        page={page}
                        siblingCount={0}
                        boundaryCount={2}
                        onChange={handlePageChange}
                        hidePrevButton
                        hideNextButton
                      />
                    </Stack>

                    {/* Right Arrow Button */}
                    {page < totalPage ? (
                      <button
                        className="right-arrow"
                        onClick={() =>
                          setPage(page * limit < totalCount ? page + 1 : page)
                        }
                        disabled={page * limit >= totalCount}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#fff"
                          className="bi bi-chevron-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                          />
                        </svg>
                      </button>
                    ) : null}
                  </div>

                  {/* Rows Per Page Selector */}
                  <div className="right-tbl-bottom">
                    <p>Rows Per Page</p>
                    <select
                      className="no-opt-box"
                      name="limit"
                      onChange={handleRowsPerPageChange}
                      value={limit}
                    >
                      {Array.from({ length: maxOption / 5 }).map((_, index) => {
                        const value = (index + 1) * 5;
                        return (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <div className="no-data-img">
                <>
                  <Alert
                    status={"info"}
                    message={"No data available."}
                    timeoutSeconds={0}
                  />
                  <NoDataFound dataType={"meeting"} />
                  <div className="button-outer">
                    <Button variant="primary" onClick={handleClearSearch}>
                      Clear
                    </Button>
                  </div>
                </>
              </div>
            )}
          </div>
          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Room</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="roomName">
                  <Form.Label>Room Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter room title"
                    value={titleName}
                    autoComplete="off"
                    onChange={(e) => setTitleName(e.target.value)}
                    onBlur={editRoomNameValidationCheck}
                  />
                </Form.Group>
                {errors.titleName && (
                  <span className="error-message">{errors.titleName}</span>
                )}
                <Form.Group controlId="roomAddress" className="mt-3">
                  <Form.Label>Room Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter room location"
                    value={roomLocation}
                    autoComplete="off"
                    onChange={(e) => setRoomLocation(e.target.value)}
                    onBlur={editRoomAddressValidationCheck}
                  />
                </Form.Group>
                {errors.roomLocation && (
                  <span className="error-message">{errors.roomLocation}</span>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button
                variant="light"
                className="btn-light"
                onClick={() => setShowEditModal(false)}
              >
                Close
              </Button> */}
              {isEditLoading ? (
                <LoaderButton />
              ) : (
                <Button variant="primary" onClick={handleEditSave}>
                  Save Changes
                </Button>
              )}
            </Modal.Footer>
          </Modal>
          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this unit?</Modal.Body>
            <Modal.Footer>
              {/* <Button
                variant="light"
                onClick={() => setShowDeleteModal(false)}
                className="btn-light"
              >
                <p>Cancel</p>
              </Button> */}
              <Button variant="primary" onClick={handleDeleteConfirm}>
                <p>Delete</p>
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default MeetingRoom;
