import React from "react";

import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
const ShowWriteMinuteYetToStartModal = (props) => {
  console.log(props);

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
      Launch demo modal
    </Button> */}

      <Modal
        show={props.isModalOpen}
        onHide={(e) => props.setIsModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Write minutes option is not available before the meeting schedule
          time.
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShowWriteMinuteYetToStartModal;
