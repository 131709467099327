import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import { Margin } from "../../../node_modules/@mui/icons-material/index";
import { getMeetingRoomList } from "../../redux/actions/meetingRoomAction/meetingRoomAction";
import { useSelector, useDispatch } from "react-redux";
import CommonStepper from "../Common/CommonStepper";
import CreateMeeting from "./CreateMeeting";
import {
  createMeetingDetails,
  getCreateMeetingStep,
  getSingleMeetingDetails,
  setMeetingViewPage,
  updateIsCreateMeetingProcessed,
} from "../../redux/actions/meetingActions/MeetingAction";
import Loader from "../Common/Loader";
import * as constantMessages from "../../constants/constatntMessages";
import "../Login/style/Login.css";
import LoaderButton from "../Common/LoaderButton";
import AddAttendees from "./AddAttendees";
import Alert from "../Common/Alert";
import AddAgendas from "./AddAgendas";
import NoDataFound from "../Common/NoDataFound";
import {
  customName,
  formatDateTimeFormat,
  getTimeSession,
} from "../../helpers/commonHelpers";
import Header from "../Common/Header/Header";
import MeetingHeader from "../Common/Header/MeetingHeader";
import Sidebar from "../Common/Sidebar/Sidebar";
import { Navigate, Link, useLocation, useNavigate } from "react-router-dom";
import { logOut } from "../../redux/actions/authActions/authAction";
import AttendeesModal from "./AttendeesModal";

const ViewMeeting = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser) {
    console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnn");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const stateData = location.state;
  console.log(stateData);
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const meetingRoomData = useSelector((state) => state.meetingRoom);
  const meetingData = useSelector((state) => state.meeting);
  const [isViewMeetingPage, setIsViewMeetingPage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attendeesData, setAttendeesData] = useState([]);
  console.log(meetingData);
  const employeeData = useSelector((state) => state.user);
  // if (employeeData?.userData === null) {
  //   localStorage.removeItem("accessToken");
  //   localStorage.removeItem("userData");
  //   localStorage.removeItem("rememberMe");
  //   navigate("/login");
  // }
  useEffect(() => {
    console.log(stateData);
    // if (stateData?.isViewMeetingPage) {
    //   setIsViewMeetingPage(stateData.isViewMeetingPage);
    //   // setFormData({
    //   //   ...formData,
    //   //   isViewMeetingPage: isViewMeetingPage,
    //   // });
    //   console.log("is dispatched->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    //   dispatch(getSingleMeetingDetails(stateData.meetingId, accessToken));
    // }

    //  dispatch(setMeetingViewPage(true, meetingData.meetingId));
    // document.title = "View Meeting: MinutesVault";
    console.log("is dispatched->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    // if (meetingData.meetingId) {
    //   console.log(
    //     "is dispatched->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>in>"
    //   );
    //   dispatch(getSingleMeetingDetails(meetingData.meetingId, accessToken));
    // }
    return () => {
      // Anything in here is fired on component unmount.
      console.log("component un mount");
      // dispatch(updateIsSuccess(false));
    };
  }, [meetingData.meetingId, meetingData.step]);
  console.log(meetingData);
  const setModalStatus = (value, attendeesData) => {
    if (attendeesData.length !== 0) {
      setIsModalOpen(value);
      setAttendeesData([...attendeesData]);
      // setIsUser(isUser)
    }
  };

  return (
    <>
      {/* {isViewMeetingPage ? (
        <> */}
      {/* <Header />
          <Sidebar /> */}
      {(meetingData.step + 1 === 1 && !meetingData.singleMeetingDetails) ||
      (meetingData.isNewMeetingPage === true &&
        !meetingData.singleMeetingDetails) ? (
        // {!meetingData.isLoading && meetingData.isNewMeetingPage ? (
        <form className="mt-2 details-form details-form-right">
       
          <Alert
            status={"info"}
            message={
              "Meeting preview will be available once you enter meeting details."
            }
            timeoutSeconds={0}
          />
          <div className="form-group mb-2">
            <div className="row">
              <NoDataFound dataType={"meeting"} />
            </div>
          </div>
        </form>
      ) : !meetingData.loading && meetingData.singleMeetingDetails ? (
        <form className="mt-2 details-form details-form-right">
          <div className="form-group mb-2">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Meeting Id</label>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <p>{meetingData.singleMeetingDetails.meetingId}</p>
              </div>
            </div>
          </div>
          <div className="form-group mb-2">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Title</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>{meetingData.singleMeetingDetails.title}</p>
              </div>
            </div>
          </div>

          <div className="form-group mb-2">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Meeting Mode</label>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <p>{meetingData.singleMeetingDetails.mode}</p>
              </div>
            </div>
          </div>

          <div className="form-group mb-2">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Location</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>
                  {meetingData?.singleMeetingDetails?.locationDetails
                    .isMeetingRoom === true
                    ?  meetingData.singleMeetingDetails?.roomDetail[0]
                    ?.title +
                  ", " +
                     meetingData?.singleMeetingDetails?.roomDetail[0]?.location
                    : meetingData?.singleMeetingDetails?.locationDetails
                        ?.location}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group mb-2">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Meeting Link</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>
                  {meetingData.singleMeetingDetails.link
                    ? meetingData.singleMeetingDetails.link
                    : "NA"}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group mb-2">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Date & Time</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>
                  {meetingData.singleMeetingDetails.date},
                  {meetingData.singleMeetingDetails.fromTime} to{" "}
                  {meetingData.singleMeetingDetails.toTime}{" "}
                </p>
              </div>
            </div>
          </div>
          {meetingData.singleMeetingDetails.attendees.length > 0 ? (
            <div className="form-group mb-2">
              <div className="row align-items-center">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                  <label className="pb-1"> Attendee(s) </label>
                </div>
                <div
                  className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                  onClick={(e) => {
                    setModalStatus(
                      true,
                      meetingData?.singleMeetingDetails?.attendees
                    );
                  }}
                >
                  <div className="attendees">
                    {meetingData.singleMeetingDetails.attendees.map(
                      (attendee) => {
                        return (
                          // <div > {customName(attendee.name)}</div>
                          <>
                            {/* <div>{customName(attendee)}</div> */}
                            <div className="attendee-list">
                              {customName(attendee.name)}
                            </div>
                          </>
                        );
                      }
                    )}
                    {/* <p className="m-0">+5 More</p> */}
                    <p className="m-0">
                      {meetingData.singleMeetingDetails.attendees.length > 5
                        ? `+${
                            meetingData.singleMeetingDetails.attendees.length -
                            5
                          } More`
                        : null}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <form className="mt-3 details-form-right">
              <Alert
                status={"info"}
                message={"No Attendee Added"}
                timeoutSeconds={0}
              />
              <div className="form-group mb-0 no-image">
                <NoDataFound dataType={"attendee"} />
              </div>
            </form>
          )}
          {meetingData.singleMeetingDetails.agendasDetail.length !== 0 ? (
            <div className="form-group agenda mt-4">
              <label className="mt-3 mb-3">
                <h4>Agenda(s)</h4>
              </label>
              {meetingData.singleMeetingDetails.agendasDetail.length !== 0 &&
                meetingData.singleMeetingDetails.agendasDetail.map(
                  (agenda, index) => {
                    return (
                      <div className="agenda-box-border">
                        
                          <div className="row">
                            <div className="col-12">
                              <label className="mt-1 p-3 topic-head">
                                {" "}
                                Agenda {index + 1}
                              </label>
                            </div>
                          </div>
                       

                        <div className="p-3">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                <label className="mt-1 mb-1">
                                  Agenda Title
                                </label>
                              </div>
                              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                <p> {agenda.title}</p>
                              </div>
                            </div>
                          </div>
                          {agenda.topic !== "" ? (
                            <div className="form-group">
                              <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                  <label className="mt-2 topic">
                                    Topic to Discuss
                                  </label>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                  <p className="mb-2">{agenda.topic} </p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className=" form-group">
                            <div className="row">
                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                <label className="mt-1 mb-1">Timeline</label>
                              </div>
                              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                <p> {agenda.timeLine} Min</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
          ) : meetingData.singleMeetingDetails.attendees.length > 0 ? (
            <form className="mt-2 details-form-right">
              <Alert
                status={"info"}
                message={"No Agenda Added"}
                timeoutSeconds={0}
              />
              <div className="form-group mb-0 no-image">
                <NoDataFound dataType={"agenda"} />
              </div>
            </form>
          ) : null}
        </form>
      ) : meetingData.isLoading ? (
        <form className="mt-2 details-form details-form-right">
          <div className="form-group mb-2 loader-icon">
            <div className="row">
              <Loader />
            </div>
          </div>
        </form>
      ) : null}
      <AttendeesModal
        IsModalOpen={isModalOpen}
        attendees={attendeesData}
        setIsModalOpen={setIsModalOpen}
        loginUserData={employeeData}
        isShow={false}
      />
    </>
  );
};

//   </>

// );
//};

export default ViewMeeting;
