import React, { useEffect, useState } from "react";
import ntsplWhiteLogo from "../../../assets/images/Ntspl-Logo-white.png";
import meetingPlusWhiteLogoForDashBoard from "../../../assets/images/minutes-vault-white-logo.png";
import meetingLogo from "../../../assets/images/meeting_logo.png";
import createmeeting from "../../../assets/images/create-meeting.svg";
import meeting2Logo from "../../../assets/images/meeting2.svg";
import actionLogo from "../../../assets/images/clipboard_839860.png";
import settingLogo from "../../../assets/images/setting.png";
import toDoList from "../../../assets/images/toDoList.png";
import { useNavigate, Navigate, Link } from "react-router-dom";
import axios from "../../../../node_modules/axios/index";
import "./Sidebar.css";
import Organization from "../../Setting/Organization/Organization";
import { useSelector, useDispatch } from "react-redux";
import { closeSidebar } from "../../../redux/actions/commonActions/commonAction";
console.log("-------------------", window.location.pathname);

const Sidebar = (props) => {
  console.log(props)
  const dispatch = useDispatch();
  const commonData = useSelector((state) => state.common);
  const employeeData = useSelector((state) => state.user);
  const [currentPath, setCurrentPath] = useState("");
  const [hover, setHover] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const [isNewPage, setIsNewPage] = useState(true);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const organizationId = userData?.organizationId;
  const accessToken = localStorage.getItem("accessToken");
  const [meetingPlusWhiteLogo, setMeetingPlusWhiteLogo] = useState();
  const paths = [
    "/alerts",
    "/meeting-room",
    "/unit",
    "/designation",
    "/department",
    "/organization",
    "/configuration",
    "/employee",
  ];

  // useEffect(() => {
  //   setCurrentPath(window.location.pathname);
  //   //  setIsNewPage(false);
  //   paths.includes(currentPath)
  //     ? setIsSubMenuOpen(true)
  //     : setIsSubMenuOpen(false);
  //   // paths.includes(currentPath)?setIsNewPage(false):setIsSubMenuOpen(true)
  //   getOrganizationData();
  // }, [currentPath]);
  useEffect(() => {
    setCurrentPath(window.location.pathname);
    //  setIsNewPage(false);
    paths.includes(currentPath)
      ? setIsSubMenuOpen(true)
      : setIsSubMenuOpen(false);
    // paths.includes(currentPath)?setIsNewPage(false):setIsSubMenuOpen(true)
    getOrganizationData();
    if(props.loginLogo==""){
       setMeetingPlusWhiteLogo(
      meetingPlusWhiteLogoForDashBoard
    )
    }
    // setMeetingPlusWhiteLogo(
    //   props?.loginLogo ? props?.loginLogo : meetingPlusWhiteLogoForDashBoard
    // );
  }, [currentPath, props.loginLogo]);

  console.log("isSubMenuOpen-------------------", isSubMenuOpen);

  const fetchOrganization = async (id) => {
    try {
      setIsLoading(true)
      if (accessToken) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/V1/organization/viewSingleOrganization/${organizationId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: accessToken,
            },
          }
        );
        if(response.data.success){
          setIsLoading(false)
        }
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching organization data:", error);
      throw error;
    }
  };
  console.log(meetingPlusWhiteLogo);
  const getOrganizationData = async () => {
    try {
      const data = await fetchOrganization(organizationId);
      console.log(data?.data);
      setMeetingPlusWhiteLogo(
        data?.data?.loginLogo!==""
          ? data?.data?.loginLogo
          : meetingPlusWhiteLogoForDashBoard
      );
    } catch (error) {
      console.error("Failed to fetch organization data:", error);
    }
  };
  console.log(commonData?.sidebarStatus);
  return (
    <div className={commonData?.sidebarStatus ? "sidebar-open-now" : ""}>
      <div className="sidebar ">
      <div className="sidebar-close"  onClick={() => dispatch(closeSidebar())}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="#ffff"
            className="bi bi-x"
            viewBox="0 0 16 16"
           
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
          </div>
        <div className="mt-4 top-logo">
          <Link to="/meeting-list">
            <div className="ntspl-logo">
              <img src={meetingPlusWhiteLogo} />
              {/* <img src={meetingPlusWhiteLogoForDashBoard} /> */}
            </div>
          </Link>
         
        </div>

        <ul className="sidebar-content">
          <div className="create-meet-sidebar">
          <Link to="/create-meeting"  state={{ isNewMeeting: true }} className="link">
              <li className={currentPath == "/create-meeting" ? "active-menu" : ""}>
                <img className="meeting-logo" src={createmeeting} />
                Create Meeting
              </li>
              </Link>
          </div>

          {/* <a href="/meeting/agenda">
            <li className="sidebar-create-meeting">
              <img className="meeting-logo" src={meetingLogo} />
              Create Meeting
            </li>
          </a> */}

          <Link to="/meeting-list" className="link">
            <li className={currentPath == "/meeting-list" ? "active-menu" : ""}>
              <img className="meeting-logo" src={meetingLogo} />
              Meetings
            </li>
          </Link>

          <Link to="/action-list" className="link">
            <li className={currentPath == "/action-list" ? "active-menu" : ""}>
              <img className="clipboard" src={actionLogo} />
              Actions
            </li>
          </Link>
          {employeeData?.userData?.isAdmin ? (
            <Link
              to=""
              className="link"
              onClick={(event) => event.preventDefault()}
            >
              <li
                className={currentPath == "manage" ? "active-menu" : ""}
                onClick={() => {
                  setIsSubMenuOpen(!isSubMenuOpen);
                }}
              >
                <img className="clipboard" src={settingLogo} />
                <div className="manage-dropdown">
                  <div>Manage</div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ffff"
                      className="bi bi-caret-down-fill drop-icon"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                </div>
              </li>
              {isSubMenuOpen ? (
                <ul className="sub-setting">
                  <Link to="/organization" className="link">
                    <li
                      className={
                        currentPath == "/organization" ? "active-menu" : ""
                      }
                    >
                      - Organization
                    </li>
                  </Link>
                  <Link to="/unit" className="link">
                    <li className={currentPath == "/unit" ? "active-menu" : ""}>
                      - Units
                    </li>
                  </Link>
                  <Link to="/department" className="link">
                    <li
                      className={
                        currentPath == "/department" ? "active-menu" : ""
                      }
                    >
                      - Departments
                    </li>
                  </Link>
                  <Link to="/designation" className="link">
                    <li
                      className={
                        currentPath == "/designation" ? "active-menu" : ""
                      }
                    >
                      - Designations
                    </li>
                  </Link>
                  <Link to="/employee" className="link">
                    <li
                      className={
                        currentPath == "/employee" ? "active-menu" : ""
                      }
                    >
                      - Employees
                    </li>
                  </Link>
                  <Link to="/meeting-room" className="link">
                    <li
                      className={
                        currentPath == "/meeting-room" ? "active-menu" : ""
                      }
                    >
                      - Meeting Rooms
                    </li>
                  </Link>
                  <Link to="/alerts" className="link">
                    <li
                      className={currentPath == "/alerts" ? "active-menu" : ""}
                      // onClick={() => {
                      //   setIsSubMenuOpen(true);
                      // }}
                    >
                      - Alerts
                    </li>
                  </Link>
                  <Link to="/configuration" className="link">
                    <li
                      className={
                        currentPath == "/configuration" ? "active-menu" : ""
                      }
                    >
                      - Configuration
                    </li>
                  </Link>
                </ul>
              ) : null}
            </Link>
          ) : null}

          {employeeData?.userData?.isAdmin ? (
            <Link to="/activity-logs" className="link">
              <li
                className={currentPath == "/activity-logs" ? "active-menu" : ""}
              >
                <img className="toDoList" src={toDoList} />
                Activity Logs
              </li>
            </Link>
          ) : null}
        </ul>

        <img className="meeting-img" src={meeting2Logo} alt="" />
      </div>
    </div>
  );
};

export default Sidebar;
