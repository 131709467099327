const { textBoxStrLength } = require("../config/config");
//import crypt from 'crypto-js';
//import sha256 from 'crypto-js/sha256';
var CryptoJS = require("crypto-js");
//var crypt = require("crypto-js");
// SALT should be created ONE TIME upon sign up
//const saltRounds = crypt.genSaltSync(10)
process.env.TZ = "Asia/Calcutta";
const customName = (fullname) => {
  console.log(fullname);
  const nameArray = fullname.split(" ");
  console.log(
    "nameArray=================================================",
    nameArray
  );
  let result;

  if (nameArray.length > 1) {
    result =
      nameArray[0].charAt(0).toUpperCase() +
      nameArray[nameArray.length - 1].charAt(0).toUpperCase();
  } else {
    result = nameArray[0].charAt(0).toUpperCase();
  }
  // console.log(
  //   "result---------------------------------->>>>>>>>>>>>>",
  //   result
  // );
  return result;
};

const formatDateTimeFormat = (date) => {
  console.log(date);
  const sourceDate = new Date(date).toDateString();
  const sourceTime = new Date(date).toLocaleTimeString();
  // The above yields e.g. 'Mon Jan 06 2020'
  console.log(sourceTime);
  const [, month, day, year] = sourceDate.split(" ");
  const formattedDate = [day, month, year].join(" ");
  // console.log(formattedDate);

  const [hour, minute, second] = sourceTime.split(" ")[0].split(":");
  const formattedTime =
    [hour, minute].join(":") + " " + sourceTime.split(" ")[1];
  return {
    formattedTime,
    formattedDate,
  };
};

const getTimeSession = (time) => {
  console.log(typeof time);
  const timeArray = time.split(":");
  const timeHour = timeArray[0];

  if (timeHour > 12) {
    return "PM";
  }
  return "AM";
};

const checkRsvpCount = (attendees) => {
  let yesCount = 0;
  let noCount = 0;
  let pendingCount = 0;
  let mayBeCount = 0;

  attendees.map((item) => {
    item.rsvp === "YES"
      ? (yesCount = yesCount + 1)
      : item.rsvp === "NO"
      ? (noCount = noCount + 1)
      : item.rsvp === "MAYBE"
      ? (mayBeCount = mayBeCount + 1)
      : (pendingCount = pendingCount + 1);
  });

  const countMessage = `${yesCount} Yes, ${noCount} No, ${mayBeCount} May Be, ${pendingCount} Awaiting`;

  return {
    yesCount,
    noCount,
    mayBeCount,
    pendingCount,
    countMessage,
  };
};

const convertFirstLetterToCapital = (text) => {
  return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
};
const convertFirstLetterOfFullNameToCapital = (textData) => {
 console.log(textData)
  const textArray = textData.split(" ");
  console.log(textArray)
  const convertedTextArray = textArray.map((text) => {
    return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
  });
  return convertedTextArray.join(" ");
};

const checkCharacterLeft = (string) => {
  return textBoxStrLength - string.length;
};

// /*FUNC TO GENERATE HASH PASSWORD*/
// const generetHashPassword = async (normalPassword) => {
//   return bcrypt.hashSync(normalPassword, saltRounds);
// };

// encrypting
//const encrypted_text = crypt("salt", "Hello"); // -> 426f666665
/*FUNC TO GENERATE HASH PASSWORD*/


  //The Function Below To Encrypt Text
  const encryptWithAES = (text) => {
    const passphrase =process.env.REACT_APP_PASSWORD_KEY;
    return CryptoJS.AES.encrypt(text, passphrase).toString();
  };

  
// const combineDateAndTime = (date, time) => {
//   let timeString = time.split(":")[0] + ":" + time.split(":")[1] + ":00";
//   console.log("timeString------------", timeString);

//   // const d = new Date();
//   console.log("normal date", date, time);

//   //console.log("get hours", date.getHours());
//   const modifiedHour =
//     time.split(":")[0] < 10
//       ? time.split(":")[0].split("")[1]
//       : time.split(":")[0];
//   const modifiedMinute =
//     time.split(":")[1] < 10
//       ? time.split(":")[1].split("")[1]
//       : time.split(":")[1];
//   console.log("modifiedHour-----------", modifiedHour);
//   console.log("modifiedMinute-----------", modifiedMinute);
//   // date.setDate(date.getDate() + 1);
//   date.setHours(date.getHours() + parseInt(modifiedHour));
//   date.setMinutes(date.getMinutes() + parseInt(modifiedMinute));
//   console.log("setted date", date);
//   return date;
 
// };
const combineDateAndTime = (date, time) => {
  let timeString = time.split(":")[0] + ":" + time.split(":")[1] + ":00";
  console.log("timeString------------", timeString);

  // const d = new Date();
  console.log("normal date", date, time.split(":")[0]);
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
 // console.log(date.setHours(0))
  console.log(date);
  const modifiedHour =
    time.split(":")[0] < 10
      ? time.split(":")[0].split("")[1]
      : time.split(":")[0];
  const modifiedMinute =
    time.split(":")[1] < 10
      ? time.split(":")[1].split("")[1]
      : time.split(":")[1];
  console.log("modifiedHour-----------", modifiedHour);
  console.log("modifiedMinute-----------", modifiedMinute);
  // date.setDate(date.getDate() + 1);
  date.setHours(date.getHours() + parseInt(modifiedHour));
  date.setMinutes(date.getMinutes() + parseInt(modifiedMinute));
  console.log("setted date", date);
  return date;
  //  date.setHours(04);
  // date.setMinutes(33);

  //   timeString = time.split(":")[0] + ":" + time.split(":")[1] + ":00";
  //   console.log("timeString------------", timeString);
  //   var year = date.getFullYear();
  //   var month = date.getMonth() + 1; // Jan is 0, dec is 11
  //   var day = date.getDate();
  //   console.log("day----------------", day);
  //   var dateString = "" + year + "-" + month + "-" + day;
  // console.log("dateString----------------",dateString)
  //   var combined = new Date(dateString + " " + timeString);
  //   console.log("combined--------------",combined)
  //   var combined2 = new Date(
  //     new Date(dateString + " " + timeString).toLocaleString("en-US", {
  //       timeZone: "UTC",
  //     })
  //   );
  //   // console.log("combined",combined)
  //   // console.log("combined2",combined2)
  //   return combined;
};

module.exports = {
  customName,
  formatDateTimeFormat,
  getTimeSession,
  checkRsvpCount,
  convertFirstLetterToCapital,
  convertFirstLetterOfFullNameToCapital,
  checkCharacterLeft,
  encryptWithAES,
  combineDateAndTime
};
